// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactNativeDrawerLayout from "./ReactNativeDrawerLayout.bs.js";

function App__Layout__Drawer(props) {
  var match = React.useState(function () {
        return false;
      });
  var setRender = match[1];
  React.useLayoutEffect((function () {
          setRender(function (param) {
                return true;
              });
        }), []);
  if (match[0]) {
    return JsxRuntime.jsx(ReactNativeDrawerLayout.make, {
                open: props.show,
                onOpen: props.onOpen,
                onClose: props.onClose,
                renderDrawerContent: props.renderDrawerContent,
                children: props.children
              });
  } else {
    return null;
  }
}

var make = App__Layout__Drawer;

export {
  make ,
}
/* react Not a pure module */
