// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as App__Auth__Password from "../password/App__Auth__Password.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__InputUtils from "../../../../primitives/src/input/Primitives__InputUtils.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as App__Auth__PasswordVisibilityToggle from "../password/App__Auth__PasswordVisibilityToggle.bs.js";

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "TogglePasswordVisibility") {
      return {
              newPassword: state.newPassword,
              confirmPassword: state.confirmPassword,
              revealPasswords: !state.revealPasswords,
              error: state.error
            };
    } else {
      return {
              newPassword: state.newPassword,
              confirmPassword: state.confirmPassword,
              revealPasswords: state.revealPasswords,
              error: undefined
            };
    }
  }
  switch (action.TAG) {
    case "ChangeNewPassword" :
        var value = action._0;
        var init = state.confirmPassword;
        return {
                newPassword: {
                  value: value,
                  validation: App__Auth__Password.regex.test(value) ? "success" : "error"
                },
                confirmPassword: {
                  value: init.value,
                  validation: state.confirmPassword.value === value ? "success" : "error"
                },
                revealPasswords: state.revealPasswords,
                error: state.error
              };
    case "ChangeConfirmPassword" :
        var value$1 = action._0;
        return {
                newPassword: state.newPassword,
                confirmPassword: {
                  value: value$1,
                  validation: state.newPassword.value === value$1 ? "success" : "error"
                },
                revealPasswords: state.revealPasswords,
                error: state.error
              };
    case "ShowError" :
        return {
                newPassword: state.newPassword,
                confirmPassword: state.confirmPassword,
                revealPasswords: state.revealPasswords,
                error: action._0
              };
    
  }
}

function make(param) {
  var toSignIn = param.toSignIn;
  var handleSubmit = param.handleSubmit;
  var receivedError = param.error;
  var match = React.useReducer(reducer, {
        newPassword: {
          value: "",
          validation: undefined
        },
        confirmPassword: {
          value: "",
          validation: undefined
        },
        revealPasswords: false,
        error: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var newPasswordRef = React.useRef(null);
  var confirmPasswordRef = React.useRef(null);
  React.useEffect((function () {
          Core__Option.forEach(receivedError, (function (error) {
                  var match = error.trim();
                  if (match === "") {
                    return ;
                  } else {
                    return dispatch({
                                TAG: "ShowError",
                                _0: "An unknown error has occurred"
                              });
                  }
                }));
        }), [receivedError]);
  var handleSubmit$1 = React.useCallback((function () {
          Primitives__InputUtils.blurFields([
                newPasswordRef,
                confirmPasswordRef
              ]);
          var newPassword = state.newPassword.value;
          var confirmPassword = state.confirmPassword.value;
          if (newPassword === "") {
            return dispatch({
                        TAG: "ShowError",
                        _0: "New password can't be empty"
                      });
          } else if (confirmPassword === "") {
            return dispatch({
                        TAG: "ShowError",
                        _0: "Confirm password can't be empty"
                      });
          } else if (newPassword !== confirmPassword) {
            return dispatch({
                        TAG: "ShowError",
                        _0: "Passwords don't match"
                      });
          } else {
            dispatch("HideError");
            return handleSubmit({
                        password: newPassword,
                        confirm_password: confirmPassword
                      });
          }
        }), [
        state.newPassword.value,
        state.confirmPassword.value,
        handleSubmit
      ]);
  var match$1 = state.newPassword.validation;
  var match$2 = state.confirmPassword.validation;
  var someFieldEmpty = [
      state.newPassword.value,
      state.confirmPassword.value
    ].some(function (field) {
        return field.trim() === "";
      });
  var someFieldInvalid = [
      state.newPassword.validation,
      state.confirmPassword.validation
    ].some(function (validation) {
        return validation === "error";
      });
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "title3",
                              children: "Change Password"
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "body2",
                              children: "A password change is required. Insert and confirm new password."
                            })
                      ]
                    }),
                ReactUtils__SimpleReact.renderIfSome(state.error, (function (error) {
                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(error),
                                    color: Primitives__Styling.color("error_1")
                                  });
                      })),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: state.newPassword.value,
                      onChange: (function (value) {
                          dispatch({
                                TAG: "ChangeNewPassword",
                                _0: value
                              });
                        }),
                      onSubmitEditing: (function () {
                          Primitives__InputUtils.focusField(confirmPasswordRef);
                        }),
                      blurOnSubmit: false,
                      returnKeyType: "next",
                      label: "New Password",
                      secureTextEntry: !state.revealPasswords,
                      placeholder: "New Password",
                      right: {
                        element: (function (param) {
                            return JsxRuntime.jsx(App__Auth__PasswordVisibilityToggle.make, {
                                        onToggle: (function () {
                                            dispatch("TogglePasswordVisibility");
                                          }),
                                        toggled: state.revealPasswords,
                                        size: param.size,
                                        color: param.color
                                      });
                          })
                      },
                      helper: match$1 !== undefined && match$1 !== "success" ? ({
                            text: "Password must contain an uppercase letter, a lowercase letter, a number and a special character (one of @ $ ! % * ? &)",
                            show: "touched"
                          }) : undefined,
                      validation: state.newPassword.validation,
                      autoCapitalize: "none",
                      autoFocus: true,
                      ref: Primitives__InputUtils.ignoreRefType(newPasswordRef)
                    }),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: state.confirmPassword.value,
                      onChange: (function (value) {
                          dispatch({
                                TAG: "ChangeConfirmPassword",
                                _0: value
                              });
                        }),
                      onSubmitEditing: handleSubmit$1,
                      returnKeyType: "done",
                      label: "Confirm password",
                      secureTextEntry: !state.revealPasswords,
                      placeholder: "Confirm password",
                      right: {
                        element: (function (param) {
                            return JsxRuntime.jsx(App__Auth__PasswordVisibilityToggle.make, {
                                        onToggle: (function () {
                                            dispatch("TogglePasswordVisibility");
                                          }),
                                        toggled: state.revealPasswords,
                                        size: param.size,
                                        color: param.color
                                      });
                          })
                      },
                      helper: match$2 !== undefined && match$2 !== "success" ? ({
                            text: "Passwords don't match",
                            show: "touched"
                          }) : undefined,
                      validation: state.confirmPassword.validation,
                      autoCapitalize: "none",
                      ref: Primitives__InputUtils.ignoreRefType(confirmPasswordRef)
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Change password",
                      onPress: (function (param) {
                          handleSubmit$1();
                        }),
                      disabled: someFieldEmpty || someFieldInvalid,
                      busy: param.isPending,
                      scaleOnHover: true
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "baseline",
                        justifyContent: "center"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Go back to"
                            }),
                        JsxRuntime.jsx(Primitives__TextButton.make, {
                              title: "Sign in",
                              onPress: (function (param) {
                                  toSignIn(undefined);
                                })
                            })
                      ]
                    })
              ]
            });
}

export {
  make ,
}
/* react Not a pure module */
