// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";

function make(param) {
  var toSignIn = param.toSignIn;
  var handleSubmit = param.handleSubmit;
  var receivedError = param.error;
  var match = Primitives__Input.use("", undefined, undefined);
  var email = match.value;
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  React.useEffect((function () {
          Core__Option.forEach(receivedError, (function (error) {
                  var match = error.trim();
                  switch (match) {
                    case "" :
                        return ;
                    case "Username/client id combination not found." :
                        return setError(function (param) {
                                    return "User with that email doesn't exist";
                                  });
                    default:
                      return setError(function (param) {
                                  return "An unknown error has occurred";
                                });
                  }
                }));
        }), [receivedError]);
  var handleSubmit$1 = React.useCallback((function () {
          var email$1 = email.trim();
          if (email$1 === "") {
            return setError(function (param) {
                        return "Email can't be empty";
                      });
          }
          setError(function (param) {
                
              });
          handleSubmit({
                username: email$1
              });
        }), [
        email,
        handleSubmit
      ]);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "title3",
                              children: "Forgot your password?"
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "body2",
                              children: "Please enter your email address to receive the reset code."
                            })
                      ]
                    }),
                ReactUtils__SimpleReact.renderIfSome(match$1[0], (function (error) {
                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(error),
                                    color: Primitives__Styling.color("error_1")
                                  });
                      })),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: email,
                      onChange: match.onChange,
                      onSubmitEditing: handleSubmit$1,
                      returnKeyType: "done",
                      label: "Email",
                      placeholder: "Email",
                      autoCapitalize: "none"
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Send email",
                      onPress: (function (param) {
                          handleSubmit$1();
                        }),
                      busy: param.isPending,
                      scaleOnHover: true
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "baseline",
                        justifyContent: "center"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Go back to"
                            }),
                        JsxRuntime.jsx(Primitives__TextButton.make, {
                              title: "Sign in",
                              onPress: (function (param) {
                                  toSignIn(undefined);
                                })
                            })
                      ]
                    })
              ]
            });
}

export {
  make ,
}
/* react Not a pure module */
