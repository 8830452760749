// Generated by ReScript, PLEASE EDIT WITH CARE


function toKebabCase(text) {
  return text.trim().toLowerCase().split("").map(function (x) {
                  if (x === " ") {
                    return "-";
                  } else {
                    return x;
                  }
                }).toString().replace(/,/g, "");
}

export {
  toKebabCase ,
}
/* No side effect */
