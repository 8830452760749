// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as App__Layout__Drawer from "./App__Layout__Drawer.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__Responsive from "../../../primitives/src/responsive/Primitives__Responsive.bs.js";

function use() {
  var match = Primitives__Responsive.useMedia();
  var match$1 = React.useState(function () {
        return false;
      });
  var setReady = match$1[1];
  React.useLayoutEffect((function () {
          setReady(function (param) {
                return true;
              });
        }), []);
  if (match$1[0]) {
    if (match.s) {
      return "Small";
    } else if (match.m) {
      return "Medium";
    } else {
      return "Large";
    }
  } else {
    return "Calculating";
  }
}

function App__Layout$NonLargeHeaderBar(props) {
  var rightElement = props.rightElement;
  var renderLeft = props.renderLeft;
  var onOpenDrawer = props.onOpenDrawer;
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_1")),
              orientation: "horizontal",
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderBottomWidth: 0.5,
                borderColor: Primitives__Styling.color({
                      NAME: "border",
                      VAL: {
                        NAME: "neutral",
                        VAL: "default"
                      }
                    }),
                justifyContent: "space-between",
                padding: Caml_option.some(Primitives__Styling.space("ss_1"))
              },
              children: Caml_option.some(rightElement !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            renderLeft(onOpenDrawer),
                            Caml_option.valFromOption(rightElement)
                          ]
                        }) : renderLeft(onOpenDrawer))
            });
}

var style_backgroundColor = Primitives__Styling.color({
      NAME: "surface",
      VAL: "default"
    });

var style_borderColor = Primitives__Styling.color({
      NAME: "border",
      VAL: {
        NAME: "neutral",
        VAL: "default"
      }
    });

var style_borderRadius = Primitives__Styling.radius("rad_5");

var style_borderWidth = 0.5;

var style_flex = 1;

var style_overflow = "hidden";

var style = {
  backgroundColor: style_backgroundColor,
  borderColor: style_borderColor,
  borderRadius: style_borderRadius,
  borderWidth: style_borderWidth,
  flex: style_flex,
  overflow: style_overflow
};

var style_borderColor$1 = Primitives__Styling.color({
      NAME: "border",
      VAL: {
        NAME: "neutral",
        VAL: "default"
      }
    });

var style_borderRightWidth = 0.5;

var style_flex$1 = 1;

var style$1 = {
  borderColor: style_borderColor$1,
  borderRightWidth: style_borderRightWidth,
  flex: style_flex$1
};

function App__Layout$NonSmallPrimaryContentContainer(props) {
  var __style = props.style;
  var style$2 = __style !== undefined ? __style : Primitives__Styling.empty;
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: RescriptLodash.merge([
                    style,
                    style$2
                  ]),
              children: Caml_option.some(props.children)
            });
}

function App__Layout$NonSmallSecondaryContentContainer(props) {
  var newrecord = Caml_obj.obj_dup(style);
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: (newrecord.maxWidth = Caml_option.some(Primitives__Styling.dp(500)), newrecord),
              children: Caml_option.some(props.children)
            });
}

function App__Layout$NonSmallNarrowMenuContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                borderColor: Primitives__Styling.color({
                      NAME: "border",
                      VAL: {
                        NAME: "neutral",
                        VAL: "default"
                      }
                    }),
                borderRightWidth: 0.5,
                width: Caml_option.some(Primitives__Styling.dp(50))
              },
              children: Caml_option.some(props.children)
            });
}

function App__Layout$NonSmallContentWrapper(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_1")),
              orientation: "horizontal",
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "subdued"
                    }),
                flex: 1,
                padding: Caml_option.some(Primitives__Styling.space("ss_1"))
              },
              children: Caml_option.some(props.children)
            });
}

function make(props) {
  var renderSecondaryMenu = props.renderSecondaryMenu;
  var tmp;
  if (renderSecondaryMenu !== undefined) {
    var newrecord = Caml_obj.obj_dup(style$1);
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(App__Layout$NonSmallNarrowMenuContainer, {
                  children: props.narrowPrimaryMenu(undefined)
                }),
            JsxRuntime.jsx(Primitives__Group.make, {
                  style: (newrecord.maxWidth = Caml_option.some(Primitives__Styling.dp(240)), newrecord),
                  children: Caml_option.some(renderSecondaryMenu(undefined, undefined))
                })
          ]
        });
  } else {
    var newrecord$1 = Caml_obj.obj_dup(style$1);
    tmp = JsxRuntime.jsx(Primitives__Group.make, {
          style: (newrecord$1.maxWidth = Caml_option.some(Primitives__Styling.dp(50 + 240)), newrecord$1),
          children: Caml_option.some(props.renderWidePrimaryMenu(undefined))
        });
  }
  var secondaryContent = props.secondaryContent;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              orientation: "horizontal",
              style: {
                flex: 1
              },
              children: [
                tmp,
                JsxRuntime.jsxs(App__Layout$NonSmallContentWrapper, {
                      children: [
                        JsxRuntime.jsx(App__Layout$NonSmallPrimaryContentContainer, {
                              children: props.primaryContent,
                              style: Core__Option.flatMap(props.styleOverrides, (function (param) {
                                      return param.nonSmallPrimaryContentContainer;
                                    }))
                            }),
                        secondaryContent !== undefined ? JsxRuntime.jsx(App__Layout$NonSmallSecondaryContentContainer, {
                                children: Caml_option.valFromOption(secondaryContent)
                              }) : null
                      ]
                    })
              ],
              hideBreakpoints: props.hideBreakpoints
            });
}

var drawerContentMaxHeight = ReactNative.Platform.OS === "web" ? "100dvh" : undefined;

var contentMaxHeight = ReactNative.Platform.OS === "web" ? "100dvh" : undefined;

function make$1(props) {
  var match = React.useState(function () {
        return false;
      });
  var setShowDrawer = match[1];
  var handleOpenDrawer = function () {
    setShowDrawer(function (param) {
          return true;
        });
  };
  var handleCloseDrawer = function () {
    setShowDrawer(function (param) {
          return false;
        });
  };
  var secondaryContent = props.secondaryContent;
  return JsxRuntime.jsx(App__Layout__Drawer.make, {
              show: match[0],
              onOpen: handleOpenDrawer,
              onClose: handleCloseDrawer,
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    style: {
                      flex: 1,
                      maxHeight: contentMaxHeight
                    },
                    children: [
                      JsxRuntime.jsx(App__Layout$NonLargeHeaderBar, {
                            onOpenDrawer: handleOpenDrawer,
                            renderLeft: props.renderHeaderLeftForNonLarge
                          }),
                      JsxRuntime.jsxs(App__Layout$NonSmallContentWrapper, {
                            children: [
                              JsxRuntime.jsx(App__Layout$NonSmallPrimaryContentContainer, {
                                    children: props.primaryContent,
                                    style: Core__Option.flatMap(props.styleOverrides, (function (param) {
                                            return param.nonSmallPrimaryContentContainer;
                                          }))
                                  }),
                              secondaryContent !== undefined ? JsxRuntime.jsx(App__Layout$NonSmallSecondaryContentContainer, {
                                      children: Caml_option.valFromOption(secondaryContent)
                                    }) : null
                            ]
                          })
                    ],
                    hideBreakpoints: props.hideBreakpoints
                  }),
              renderDrawerContent: (function () {
                  var renderSecondaryMenu = props.renderSecondaryMenu;
                  if (renderSecondaryMenu !== undefined) {
                    return JsxRuntime.jsxs(Primitives__Group.make, {
                                orientation: "horizontal",
                                style: {
                                  flex: 1,
                                  maxHeight: drawerContentMaxHeight
                                },
                                children: [
                                  JsxRuntime.jsx(App__Layout$NonSmallNarrowMenuContainer, {
                                        children: props.narrowPrimaryMenu(handleCloseDrawer)
                                      }),
                                  JsxRuntime.jsx(Primitives__Group.make, {
                                        style: {
                                          flex: 1
                                        },
                                        children: Caml_option.some(renderSecondaryMenu(undefined, handleCloseDrawer))
                                      })
                                ]
                              });
                  } else {
                    return JsxRuntime.jsx(Primitives__Group.make, {
                                style: {
                                  flex: 1,
                                  maxHeight: drawerContentMaxHeight
                                },
                                children: Caml_option.some(props.renderWidePrimaryMenu(undefined))
                              });
                  }
                })
            });
}

function make$2(props) {
  var match = React.useState(function () {
        return false;
      });
  var setShowDrawer = match[1];
  var match$1 = React.useState(function () {
        return "Primary";
      });
  var setMenuToDisplay = match$1[1];
  var menuToDisplay = match$1[0];
  React.useLayoutEffect((function () {
          var match = props.renderSecondaryMenu;
          if (match !== undefined) {
            setMenuToDisplay(function (param) {
                  return "Secondary";
                });
          }
          
        }), [props.renderSecondaryMenu]);
  var handleOpenDrawer = function () {
    setShowDrawer(function (param) {
          return true;
        });
  };
  var handleCloseDrawer = function () {
    setShowDrawer(function (param) {
          return false;
        });
  };
  return JsxRuntime.jsx(App__Layout__Drawer.make, {
              show: match[0],
              onOpen: handleOpenDrawer,
              onClose: handleCloseDrawer,
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    style: {
                      flex: 1,
                      maxHeight: contentMaxHeight
                    },
                    children: [
                      JsxRuntime.jsx(App__Layout$NonLargeHeaderBar, {
                            onOpenDrawer: handleOpenDrawer,
                            renderLeft: props.renderHeaderLeftForNonLarge,
                            rightElement: props.headerRightElementForNonLarge
                          }),
                      JsxRuntime.jsx(Primitives__Group.make, {
                            style: {
                              flex: 1
                            },
                            children: Caml_option.some(props.primaryContent)
                          })
                    ],
                    hideBreakpoints: props.hideBreakpoints
                  }),
              renderDrawerContent: (function () {
                  var renderSecondaryMenu = props.renderSecondaryMenu;
                  var tmp;
                  tmp = renderSecondaryMenu !== undefined ? (
                      menuToDisplay === "Primary" ? props.renderWidePrimaryMenu((function () {
                                setMenuToDisplay(function (param) {
                                      return "Secondary";
                                    });
                              })) : renderSecondaryMenu((function () {
                                setMenuToDisplay(function (param) {
                                      return "Primary";
                                    });
                              }), handleCloseDrawer)
                    ) : props.renderWidePrimaryMenu(undefined);
                  return JsxRuntime.jsx(Primitives__Group.make, {
                              style: {
                                flex: 1,
                                maxHeight: drawerContentMaxHeight
                              },
                              children: Caml_option.some(tmp)
                            });
                })
            });
}

function props_narrowPrimaryMenu(param) {
  return null;
}

function props_renderWidePrimaryMenu(param) {
  return null;
}

function props_renderHeaderLeftForNonLarge(param) {
  return null;
}

var props = {
  narrowPrimaryMenu: props_narrowPrimaryMenu,
  renderWidePrimaryMenu: props_renderWidePrimaryMenu,
  primaryContent: null,
  renderHeaderLeftForNonLarge: props_renderHeaderLeftForNonLarge
};

function App__Layout$Calculating(props$1) {
  var secondaryContent = Core__Option.isSome(props$1.secondaryContent) ? Caml_option.some(null) : undefined;
  var renderSecondaryMenu = Core__Option.isSome(props$1.renderSecondaryMenu) ? (function (param, param$1) {
        return null;
      }) : undefined;
  var newrecord = Caml_obj.obj_dup(props);
  var newrecord$1 = Caml_obj.obj_dup(props);
  var newrecord$2 = Caml_obj.obj_dup(props);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(make$2, (newrecord.hideBreakpoints = [
                        "m",
                        "l",
                        "xl"
                      ], newrecord)),
                JsxRuntime.jsx(make$1, (newrecord$1.hideBreakpoints = [
                        "s",
                        "l",
                        "xl"
                      ], newrecord$1.secondaryContent = secondaryContent, newrecord$1.renderSecondaryMenu = renderSecondaryMenu, newrecord$1)),
                JsxRuntime.jsx(make, (newrecord$2.hideBreakpoints = [
                        "s",
                        "m"
                      ], newrecord$2.headerRightElementForNonLarge = props$1.headerRightElementForNonLarge, newrecord$2.renderHeaderLeftForNonLarge = props$1.renderHeaderLeftForNonLarge, newrecord$2.secondaryContent = secondaryContent, newrecord$2.renderSecondaryMenu = renderSecondaryMenu, newrecord$2))
              ]
            });
}

function make$3(props) {
  var screenSize = use();
  switch (screenSize) {
    case "Small" :
        return JsxRuntime.jsx(make$2, props);
    case "Medium" :
        return JsxRuntime.jsx(make$1, props);
    case "Large" :
        return JsxRuntime.jsx(make, props);
    case "Calculating" :
        return JsxRuntime.jsx(App__Layout$Calculating, {
                    secondaryContent: props.secondaryContent,
                    renderSecondaryMenu: props.renderSecondaryMenu,
                    renderHeaderLeftForNonLarge: props.renderHeaderLeftForNonLarge,
                    headerRightElementForNonLarge: props.headerRightElementForNonLarge
                  });
    
  }
}

export {
  make$3 as make,
}
/* style Not a pure module */
