// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Routing__Link from "../../../routing/src/link/Routing__Link.bs.js";
import * as Routing__Utils from "../../../routing/src/utils/Routing__Utils.bs.js";
import * as Routing__Params from "../../../routing/src/params/Routing__Params.bs.js";
import * as Routing__Router from "../../../routing/src/router/Routing__Router.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptGlobal__Url from "../../../rescript-global/src/url/RescriptGlobal__Url.bs.js";
import * as App__Organization__Utils from "../organization/utils/App__Organization__Utils.bs.js";

function shortenId(nodeId) {
  return DecidrInternalId.toString(DecidrNodeId.getInternalId(nodeId));
}

var pathname = "organization";

var pathname$1 = "chats";

var View = {
  viewId: "viewId"
};

var Channel = {
  channelId: "channelId"
};

var pathname$2 = "spaces";

var OrganizationSpace = {
  spaceId: "spaceId"
};

var Feed = {
  inviteId: "invite_id"
};

var Platform = {
  id: "id"
};

var Space = {
  spaceId: "spaceId"
};

var ViewOrRow = {
  preview: "preview",
  parentColumnViewId: "parentColumnViewId"
};

function useNodeId(param, typename) {
  var match = Routing__Params.useString(param);
  var setParam = match[1];
  var shortenedId = match[0];
  var extendedId = React.useMemo((function () {
          return Core__Option.map(Core__Option.flatMap(shortenedId, DecidrInternalId.fromString), (function (__x) {
                        return DecidrNodeId.make(typename, __x);
                      }));
        }), [shortenedId]);
  var setShortenedIdParam = React.useCallback((function (extendedId, options) {
          setParam(Core__Option.map(extendedId, shortenId), options);
        }), [setParam]);
  return [
          extendedId,
          setShortenedIdParam
        ];
}

function useInternalId(param) {
  var match = Routing__Params.useString(param);
  var setId = match[1];
  var setInternalId = React.useCallback((function (id, options) {
          setId(Core__Option.map(id, DecidrInternalId.toString), options);
        }), [setId]);
  return [
          Core__Option.flatMap(match[0], DecidrInternalId.fromString),
          setInternalId
        ];
}

var Routes = {};

function makeOrgChannelIdParam(channelId, persistChannelOpt) {
  var persistChannel = persistChannelOpt !== undefined ? persistChannelOpt : false;
  if (persistChannel) {
    return Core__Option.map(channelId, (function (channelId) {
                  return [
                          "channelId",
                          RescriptGlobal__Url.QueryParams.make({
                                NAME: "String",
                                VAL: channelId
                              })
                        ];
                }));
  }
  
}

function routeToUrlObject(route) {
  if (typeof route !== "object") {
    return {
            pathname: "/"
          };
  }
  switch (route.TAG) {
    case "Organization" :
        var params = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params))
              };
    case "OrganizationFeed" :
        var params$1 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/feed",
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$1))
              };
    case "OrganizationChannel" :
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/" + pathname$1 + "/" + shortenId(route.channelId)
              };
    case "OrganizationChannels" :
        var params$2 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/" + pathname$1,
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$2))
              };
    case "OrganizationSpaces" :
        var params$3 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/" + pathname$2,
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$3))
              };
    case "OrganizationSpace" :
        var params$4 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/" + pathname$2 + "/" + shortenId(route.spaceId),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$4))
              };
    case "OrganizationSettings" :
        var params$5 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/settings",
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$5))
              };
    case "OrganizationViewSettings" :
        var parentRowId = Core__Option.map(route.parentRowId, (function (parentRowId) {
                return [
                        "parentRowId",
                        RescriptGlobal__Url.QueryParams.make({
                              NAME: "String",
                              VAL: DecidrInternalId.toString(parentRowId)
                            })
                      ];
              }));
        var parentViewId = Core__Option.map(route.parentViewId, (function (parentViewId) {
                return [
                        "parentViewId",
                        RescriptGlobal__Url.QueryParams.make({
                              NAME: "String",
                              VAL: DecidrInternalId.toString(DecidrNodeId.getInternalId(parentViewId))
                            })
                      ];
              }));
        var params$6 = Routing__Utils.makeParams([
              makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined),
              parentRowId,
              parentViewId
            ]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/view-settings/" + shortenId(route.viewId),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$6))
              };
    case "OrganizationPageSettings" :
        var parentRowId$1 = Core__Option.map(route.parentRowId, (function (parentRowId) {
                return [
                        "parentRowId",
                        RescriptGlobal__Url.QueryParams.make({
                              NAME: "String",
                              VAL: DecidrInternalId.toString(parentRowId)
                            })
                      ];
              }));
        var params$7 = Routing__Utils.makeParams([
              makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined),
              parentRowId$1
            ]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/page-settings/" + shortenId(route.viewId) + "/" + DecidrInternalId.toString(route.rowId),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$7))
              };
    case "OrganizationColumnViewSettings" :
        var params$8 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/column-view-settings/" + shortenId(route.columnViewId),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$8))
              };
    case "OrganizationTableSettings" :
        var params$9 = Routing__Utils.makeParams([makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), undefined)]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/table-settings/" + shortenId(route.tableId),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$9))
              };
    case "OrganizationViewOrRow" :
        var params$10 = Routing__Utils.makeParams([
              makeOrgChannelIdParam(Core__Option.map(route.channelId, shortenId), route.persistChannel),
              Core__Option.map(route.preview, (function (preview) {
                      return [
                              "preview",
                              RescriptGlobal__Url.QueryParams.make({
                                    NAME: "Bool",
                                    VAL: preview
                                  })
                            ];
                    })),
              Core__Option.map(Core__Option.map(route.parentColumnViewId, shortenId), (function (parentColumnViewId) {
                      return [
                              "parentColumnViewId",
                              RescriptGlobal__Url.QueryParams.make({
                                    NAME: "String",
                                    VAL: parentColumnViewId
                                  })
                            ];
                    }))
            ]);
        return {
                pathname: "/" + pathname + "/" + shortenId(route.id) + "/" + route.segments.join("/"),
                query: Caml_option.some(RescriptGlobal__Url.Query.make(params$10))
              };
    
  }
}

function make(param) {
  return JsxRuntime.jsx(Routing__Link.make, {
              href: Routing__Link.makeHrefObject(routeToUrlObject(param.route)),
              children: param.children,
              "aria-label": param.a11yLabel
            });
}

var Link = {
  make: make
};

function updateOrganizationHeaderForOrganizationRoutes(route) {
  if (typeof route !== "object") {
    return App__Organization__Utils.unsetOrganizationHeader();
  } else {
    return App__Organization__Utils.setOrganizationHeader(route.id);
  }
}

function push(router, route, as, transitionOptions) {
  updateOrganizationHeaderForOrganizationRoutes(route);
  Routing__Router.pushObject(router, routeToUrlObject(route), as, transitionOptions, undefined);
}

function replace(router, route, as, transitionOptions) {
  updateOrganizationHeaderForOrganizationRoutes(route);
  Routing__Router.replaceObject(router, routeToUrlObject(route), as, transitionOptions, undefined);
}

var Constants_Organization = {
  id: "id",
  channelId: "channelId"
};

var Constants_ViewSettings = {
  parentRowId: "parentRowId",
  parentViewId: "parentViewId"
};

var Constants_PageSettings = {
  viewId: "viewId",
  rowId: "rowId",
  parentRowId: "parentRowId"
};

var Constants_ColumnViewSettings = {
  columnViewId: "columnViewId"
};

var Constants_TableSettings = {
  tableId: "tableId"
};

var Constants_OrganizationSpaces = {};

var Constants_OrganizationSettings = {};

var Constants = {
  Organization: Constants_Organization,
  View: View,
  ViewSettings: Constants_ViewSettings,
  PageSettings: Constants_PageSettings,
  ColumnViewSettings: Constants_ColumnViewSettings,
  TableSettings: Constants_TableSettings,
  Channel: Channel,
  OrganizationSpaces: Constants_OrganizationSpaces,
  OrganizationSpace: OrganizationSpace,
  OrganizationSettings: Constants_OrganizationSettings,
  Feed: Feed,
  Platform: Platform,
  Space: Space,
  ViewOrRow: ViewOrRow
};

export {
  Constants ,
  useNodeId ,
  useInternalId ,
  Routes ,
  Link ,
  push ,
  replace ,
}
/* react Not a pure module */
