// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "./utils";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";

function unsetOrganizationHeader(prim) {
  Utils.unsetOrganizationHeader();
}

function setOrganizationHeader(organizationId) {
  var prim = DecidrNodeId.getInternalId(organizationId);
  Utils.setOrganizationHeader(prim);
}

export {
  setOrganizationHeader ,
  unsetOrganizationHeader ,
}
/* ./utils Not a pure module */
