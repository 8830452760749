// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "aws-amplify/auth";

function signOut() {
  return Auth.signOut();
}

export {
  signOut ,
}
/* aws-amplify/auth Not a pure module */
