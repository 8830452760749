// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as Primitives__Skeleton__Text from "../../../../primitives/src/skeleton/Primitives__Skeleton__Text.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__Shield from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Shield.bs.js";

function App__Organization__WideItem$ImageFallback(props) {
  var __icon = props.icon;
  var icon = __icon !== undefined ? __icon : RescriptTamagui__Icon__Shield.make;
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_1"),
                alignItems: "center",
                height: Caml_option.some(Primitives__Styling.dp(32)),
                justifyContent: "center",
                width: Caml_option.some(Primitives__Styling.dp(32))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                        size: "ic_4",
                        icon: icon
                      }))
            });
}

function App__Organization__WideItem$TitleContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_2"))
              },
              children: Caml_option.some(props.children)
            });
}

function App__Organization__WideItem$ImageContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                borderRadius: Primitives__Styling.radius("rad_3"),
                overflow: "hidden"
              },
              children: Caml_option.some(props.children)
            });
}

function App__Organization__WideItem$Error(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
              orientation: "horizontal",
              style: {
                borderColor: Primitives__Styling.color("transparent"),
                borderWidth: 2.5,
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(App__Organization__WideItem$ImageContainer, {
                      children: JsxRuntime.jsx(Primitives__Group.make, {
                            style: {
                              backgroundColor: Primitives__Styling.color("error_2"),
                              height: Caml_option.some(Primitives__Styling.dp(32)),
                              width: Caml_option.some(Primitives__Styling.dp(32))
                            }
                          })
                    }),
                JsxRuntime.jsx(App__Organization__WideItem$TitleContainer, {
                      children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                            style: {
                              fontWeight: 500
                            },
                            children: "Error",
                            color: Primitives__Styling.color("error_1"),
                            numberOfLines: 1
                          })
                    })
              ]
            });
}

var $$Error = {
  make: App__Organization__WideItem$Error
};

function App__Organization__WideItem$Loading(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
              orientation: "horizontal",
              style: {
                borderColor: Primitives__Styling.color("transparent"),
                borderWidth: 2.5,
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(App__Organization__WideItem$ImageContainer, {
                      children: JsxRuntime.jsx(App__Organization__WideItem$ImageFallback, {
                            icon: props.icon
                          })
                    }),
                JsxRuntime.jsx(App__Organization__WideItem$TitleContainer, {
                      children: JsxRuntime.jsx(Primitives__Skeleton__Text.make, {
                            maxWidth: 120,
                            style: {
                              flex: 1
                            }
                          })
                    })
              ]
            });
}

var Loading = {
  make: App__Organization__WideItem$Loading
};

function App__Organization__WideItem(props) {
  var onPress = props.onPress;
  var image = props.image;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                    orientation: "horizontal",
                    style: {
                      alignItems: "center"
                    },
                    children: [
                      JsxRuntime.jsx(App__Organization__WideItem$ImageContainer, {
                            children: image !== undefined ? JsxRuntime.jsx(Primitives__DecidrImage.make, {
                                    src: image.url,
                                    size: {
                                      TAG: "Square",
                                      _0: Primitives__Styling.dp(32)
                                    },
                                    alt: image.alt
                                  }) : JsxRuntime.jsx(App__Organization__WideItem$ImageFallback, {
                                    icon: props.fallbackIcon
                                  })
                          }),
                      JsxRuntime.jsx(App__Organization__WideItem$TitleContainer, {
                            children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                                  style: {
                                    fontWeight: 500
                                  },
                                  children: Caml_option.some(props.name),
                                  numberOfLines: 1
                                })
                          })
                    ]
                  }),
              style: {
                borderWidth: 0,
                width: Caml_option.some(Primitives__Styling.pct(100))
              },
              onPress: (function (param) {
                  onPress();
                }),
              hoverStyle: {
                backgroundColor: Primitives__Styling.color("neutral_8")
              }
            });
}

var make = App__Organization__WideItem;

export {
  $$Error ,
  Loading ,
  make ,
}
/* Primitives__Icon Not a pure module */
