// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__InputUtils from "../../../../primitives/src/input/Primitives__InputUtils.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as App__Auth__PasswordVisibilityToggle from "../password/App__Auth__PasswordVisibilityToggle.bs.js";

function make(param) {
  var toSignUp = param.toSignUp;
  var toForgotPassword = param.toForgotPassword;
  var handleSubmit = param.handleSubmit;
  var receivedError = param.error;
  var match = Primitives__Input.use("", undefined, undefined);
  var email = match.value;
  var match$1 = Primitives__Input.use("", undefined, undefined);
  var password = match$1.value;
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowPassword = match$2[1];
  var showPassword = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var emailRef = React.useRef(null);
  var passwordRef = React.useRef(null);
  React.useEffect((function () {
          Core__Option.forEach(receivedError, (function (error) {
                  var match = error.trim();
                  switch (match) {
                    case "" :
                        return ;
                    case "Incorrect username or password." :
                    case "User does not exist." :
                        break;
                    default:
                      return setError(function (param) {
                                  return "An unknown error has occurred";
                                });
                  }
                  setError(function (param) {
                        return "Incorrect email or password";
                      });
                }));
        }), [receivedError]);
  var handleSubmit$1 = React.useCallback((function () {
          Primitives__InputUtils.blurFields([
                emailRef,
                passwordRef
              ]);
          var email$1 = email.trim();
          if (email$1 === "") {
            return setError(function (param) {
                        return "Email can't be empty";
                      });
          }
          if (password === "") {
            return setError(function (param) {
                        return "Password can't be empty";
                      });
          }
          setError(function (param) {
                
              });
          handleSubmit({
                username: email$1,
                password: password
              });
        }), [
        email,
        password,
        handleSubmit
      ]);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: 500,
                        textAlign: "center"
                      },
                      kind: "title3",
                      children: "Welcome"
                    }),
                ReactUtils__SimpleReact.renderIfSome(match$3[0], (function (error) {
                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(error),
                                    color: Primitives__Styling.color("error_1")
                                  });
                      })),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: email,
                      onChange: match.onChange,
                      onSubmitEditing: (function () {
                          Primitives__InputUtils.focusField(passwordRef);
                        }),
                      blurOnSubmit: false,
                      returnKeyType: "next",
                      label: "Email",
                      placeholder: "Email",
                      autoCapitalize: "none",
                      ref: Primitives__InputUtils.ignoreRefType(emailRef)
                    }),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: password,
                      onChange: match$1.onChange,
                      onSubmitEditing: handleSubmit$1,
                      blurOnSubmit: true,
                      returnKeyType: "done",
                      label: "Password",
                      secureTextEntry: !showPassword,
                      action: {
                        text: "Forgot password?",
                        onPress: (function () {
                            toForgotPassword(undefined);
                          }),
                        focusable: false
                      },
                      placeholder: "Password",
                      right: {
                        element: (function (param) {
                            return JsxRuntime.jsx(App__Auth__PasswordVisibilityToggle.make, {
                                        onToggle: (function () {
                                            setShowPassword(function (prev) {
                                                  return !prev;
                                                });
                                          }),
                                        toggled: showPassword,
                                        size: param.size,
                                        color: param.color
                                      });
                          })
                      },
                      autoCapitalize: "none",
                      ref: Primitives__InputUtils.ignoreRefType(passwordRef)
                    }),
                JsxRuntime.jsx(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Button.make, {
                                title: "Sign in",
                                onPress: (function (param) {
                                    handleSubmit$1();
                                  }),
                                busy: param.isPending,
                                scaleOnHover: true
                              }))
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "baseline",
                        flexWrap: "wrap",
                        justifyContent: "center"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Don't have an account?"
                            }),
                        JsxRuntime.jsx(Primitives__TextButton.make, {
                              title: "Get Started!",
                              onPress: (function (param) {
                                  toSignUp(undefined);
                                })
                            })
                      ]
                    })
              ]
            });
}

export {
  make ,
}
/* react Not a pure module */
