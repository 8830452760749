// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App__SignOut from "../../auth/signOut/App__SignOut.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as App__ErrorMessage from "../../error/App__ErrorMessage.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Utils__PersonalName from "../../../../utils/src/personalName/Utils__PersonalName.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Icon__X from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__X.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__IconProps from "../../../../rescript-tamagui/src/icons/RescriptTamagui__IconProps.bs.js";
import * as RescriptTamagui__Icon__User from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__User.bs.js";
import * as RescriptTamagui__Icon__LogOut from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__LogOut.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as App__LoggedInUser__FullPageError from "../loggedInUserError/App__LoggedInUser__FullPageError.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as App__LoggedInUser__FullPageCreating from "../loggedInUserCreating/App__LoggedInUser__FullPageCreating.bs.js";
import * as Primitives__NativeConditionalRender from "../../../../primitives/src/conditional/Primitives__NativeConditionalRender.bs.js";
import * as App__LoggedInUser__OnboardingFragment from "./App__LoggedInUser__OnboardingFragment.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql from "../../../../relay-codegen/generated/AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.bs.js";

function App__LoggedInUser__Onboarding$Title(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        textAlign: "center"
                      },
                      kind: "title3",
                      children: Caml_option.some(props.title)
                    }),
                ReactUtils__SimpleReact.renderIfSome(props.subtitle, (function (subtitle) {
                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(subtitle)
                                  });
                      }))
              ]
            });
}

function App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePicture(props) {
  return JsxRuntime.jsx(Primitives__DecidrImage.make, {
              src: props.src,
              size: {
                TAG: "Responsive",
                _0: {
                  s: {
                    width: Caml_option.some(Primitives__Styling.dp(64)),
                    height: Caml_option.some(Primitives__Styling.dp(64))
                  },
                  m: {
                    width: Caml_option.some(Primitives__Styling.dp(64)),
                    height: Caml_option.some(Primitives__Styling.dp(64))
                  },
                  l: {
                    width: Caml_option.some(Primitives__Styling.dp(80)),
                    height: Caml_option.some(Primitives__Styling.dp(80))
                  },
                  xl: {
                    width: Caml_option.some(Primitives__Styling.dp(80)),
                    height: Caml_option.some(Primitives__Styling.dp(80))
                  }
                }
              },
              style: {
                borderRadius: Primitives__Styling.radius("rad_5")
              },
              alt: "User profile picture"
            });
}

var commonStyles_backgroundColor = Primitives__Styling.color({
      NAME: "background",
      VAL: "default"
    });

var commonStyles_borderColor = Primitives__Styling.color({
      NAME: "border",
      VAL: {
        NAME: "neutral",
        VAL: "hover"
      }
    });

var commonStyles_borderRadius = Primitives__Styling.radius("rad_5");

var commonStyles_borderStyle = "dashed";

var commonStyles_borderWidth = 1;

var commonStyles_alignItems = "center";

var commonStyles_justifyContent = "center";

var commonStyles = {
  backgroundColor: commonStyles_backgroundColor,
  borderColor: commonStyles_borderColor,
  borderRadius: commonStyles_borderRadius,
  borderStyle: commonStyles_borderStyle,
  borderWidth: commonStyles_borderWidth,
  alignItems: commonStyles_alignItems,
  justifyContent: commonStyles_justifyContent
};

function App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePicturePlaceholder(props) {
  var newrecord = Caml_obj.obj_dup(commonStyles);
  var newrecord$1 = Caml_obj.obj_dup(commonStyles);
  return JsxRuntime.jsxs(Primitives__NativeConditionalRender.make, {
              children: [
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: (newrecord.width = Caml_option.some(Primitives__Styling.dp(80)), newrecord.height = Caml_option.some(Primitives__Styling.dp(80)), newrecord),
                      children: Caml_option.some(JsxRuntime.jsx(RescriptTamagui__Icon__User.make, {
                                size: Caml_option.some(RescriptTamagui__IconProps.Size.dp(64)),
                                color: Primitives__Styling.color("primary_2")
                              })),
                      hideBreakpoints: [
                        "s",
                        "m"
                      ]
                    }),
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: (newrecord$1.width = Caml_option.some(Primitives__Styling.dp(64)), newrecord$1.height = Caml_option.some(Primitives__Styling.dp(64)), newrecord$1),
                      children: Caml_option.some(JsxRuntime.jsx(RescriptTamagui__Icon__User.make, {
                                size: Caml_option.some(RescriptTamagui__IconProps.Size.dp(48)),
                                color: Primitives__Styling.color("primary_2")
                              })),
                      hideBreakpoints: [
                        "l",
                        "xl"
                      ]
                    })
              ]
            });
}

function App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePictureUpload(props) {
  var onProfilePictureChange = props.onProfilePictureChange;
  var profilePictureSrc = props.profilePictureSrc;
  var newrecord = Caml_obj.obj_dup(Primitives__UnstyledButton.Focused.style);
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                    orientation: "horizontal",
                    style: {
                      paddingRight: Caml_option.some(Primitives__Styling.space("cs_2"))
                    },
                    children: [
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            children: [
                              profilePictureSrc !== undefined ? JsxRuntime.jsx(App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePicture, {
                                      src: profilePictureSrc
                                    }) : JsxRuntime.jsx(App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePicturePlaceholder, {}),
                              ReactUtils__SimpleReact.whenTrue(Core__Option.isSome(profilePictureSrc), {
                                    LAZY_DONE: false,
                                    VAL: (function () {
                                        return JsxRuntime.jsx(Primitives__IconButton.make, {
                                                    kind: "secondary",
                                                    icon: RescriptTamagui__Icon__X.make,
                                                    onPress: (function (e) {
                                                        Primitives__Button.$$Event.stopPropagation(e);
                                                        onProfilePictureChange(undefined);
                                                      }),
                                                    size: "small",
                                                    style: {
                                                      position: "absolute",
                                                      right: Caml_option.some(Primitives__Styling.dp(-12)),
                                                      top: Caml_option.some(Primitives__Styling.dp(-12)),
                                                      zIndex: 2
                                                    }
                                                  });
                                      })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            style: {
                              alignItems: "flex-start",
                              flex: 1,
                              justifyContent: "center"
                            },
                            children: [
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500
                                    },
                                    kind: "button",
                                    children: Caml_option.some(profilePictureSrc !== undefined ? "Change image" : "+ Upload image"),
                                    color: Primitives__Styling.color("primary_1")
                                  }),
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    kind: "body3",
                                    children: "Help your teammates recognize you."
                                  })
                            ]
                          })
                    ]
                  }),
              style: (newrecord.alignSelf = "flex-start", newrecord),
              onPress: (function (param) {
                  onProfilePictureChange("FIXME AB");
                }),
              focusStyle: Primitives__UnstyledButton.Focused.focusStyle
            });
}

var inputSpecificStyles = {
  l: {
    flex: 1
  },
  xl: {
    flex: 1
  }
};

var convertVariables = AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.Internal.convertVariables;

var convertResponse = AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, AppLoggedInUserOnboarding_updateOrganizationMemberHumanMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useUpdateOrganizationMemberHuman() {
  var match = use();
  var updateOrganizationMemberHuman = match[0];
  var handleUpdateOrganizationMemberHuman = function (id, name, surname, param, onError, onCompleted) {
    updateOrganizationMemberHuman({
          id: id,
          name: name,
          surname: surname
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating organization member", "App__LoggedInUser__Onboarding useUpdateOrganizationMemberHuman", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating organization member", "App__LoggedInUser__Onboarding useUpdateOrganizationMemberHuman", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleUpdateOrganizationMemberHuman,
          match[1]
        ];
}

function App__LoggedInUser__Onboarding$UserBasicInfo(props) {
  var initialProfilePictureSrc = props.initialProfilePictureSrc;
  var onNext = props.onNext;
  var match = Primitives__Input.use(props.initialName, undefined, undefined);
  var name = match.value;
  var match$1 = Primitives__Input.use(props.initialSurname, undefined, undefined);
  var surname = match$1.value;
  var match$2 = React.useState(function () {
        return initialProfilePictureSrc;
      });
  var setProfilePictureSrc = match$2[1];
  var profilePictureSrc = match$2[0];
  var submitDisabled = name.trim() === "" || surname.trim() === "";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(App__LoggedInUser__Onboarding$Title, {
                      title: "What's your name?",
                      subtitle: "Adding your name and profile photo helps people and your teammates recognize and connect with you more easily."
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                      specificStyles: {
                        s: {
                          flexDirection: "column"
                        },
                        m: {
                          flexDirection: "row"
                        },
                        l: {
                          flexDirection: "row"
                        },
                        xl: {
                          flexDirection: "row"
                        }
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__Input.make, {
                              value: name,
                              onChange: match.onChange,
                              label: "Name",
                              placeholder: "John",
                              specificStyles: inputSpecificStyles
                            }),
                        JsxRuntime.jsx(Primitives__Input.make, {
                              value: surname,
                              onChange: match$1.onChange,
                              label: "Surname",
                              placeholder: "Johnson",
                              specificStyles: inputSpecificStyles
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                fontWeight: 500
                              },
                              children: "Profile picture (optional)"
                            }),
                        JsxRuntime.jsx(App__LoggedInUser__Onboarding$UserBasicInfo$ProfilePictureUpload, {
                              profilePictureSrc: profilePictureSrc,
                              onProfilePictureChange: (function (profilePictureSrc) {
                                  setProfilePictureSrc(function (param) {
                                        return profilePictureSrc;
                                      });
                                })
                            })
                      ]
                    }),
                JsxRuntime.jsx(App__ErrorMessage.make, {
                      error: props.errorUpdatingProfile
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Finish",
                      style: {
                        alignSelf: "center"
                      },
                      onPress: (function (param) {
                          onNext(name, surname, profilePictureSrc);
                        }),
                      disabled: submitDisabled,
                      busy: props.updatingProfile,
                      scaleOnHover: true
                    })
              ]
            });
}

var smallContainerStyle = {
  alignSelf: "flex-end"
};

var largeContainerStyle_position = "absolute";

var largeContainerStyle_right = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeContainerStyle_top = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeContainerStyle = {
  position: largeContainerStyle_position,
  right: largeContainerStyle_right,
  top: largeContainerStyle_top
};

function App__LoggedInUser__Onboarding$SignedInAsInfo(props) {
  var displayName = Core__Option.orElse(props.email, props.username);
  return JsxRuntime.jsx(Primitives__NativeConditionalRender.make, {
              children: JsxRuntime.jsx(Primitives__Group.make, {
                    specificStyles: {
                      s: smallContainerStyle,
                      m: smallContainerStyle,
                      l: largeContainerStyle,
                      xl: largeContainerStyle
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                              children: JsxRuntime.jsxs(Primitives__Group.make, {
                                    gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                                    orientation: "horizontal",
                                    style: {
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      maxWidth: Caml_option.some(Primitives__Styling.dp(200)),
                                      paddingBottom: Caml_option.some(Primitives__Styling.space("cs_1")),
                                      paddingLeft: Caml_option.some(Primitives__Styling.space("cs_4")),
                                      paddingRight: Caml_option.some(Primitives__Styling.space("cs_4")),
                                      paddingTop: Caml_option.some(Primitives__Styling.space("cs_1"))
                                    },
                                    children: [
                                      displayName !== undefined ? JsxRuntime.jsxs(Primitives__Group.make, {
                                              style: {
                                                flex: 1
                                              },
                                              children: [
                                                JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                      style: {
                                                        textAlign: "right"
                                                      },
                                                      kind: "body3",
                                                      children: "Signed in as"
                                                    }),
                                                JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                      style: {
                                                        fontWeight: 500,
                                                        textAlign: "right"
                                                      },
                                                      kind: "body3",
                                                      children: Caml_option.some(displayName),
                                                      numberOfLines: 1
                                                    })
                                              ]
                                            }) : null,
                                      JsxRuntime.jsx(Primitives__Icon.make, {
                                            size: "ic_1",
                                            icon: RescriptTamagui__Icon__LogOut.make
                                          })
                                    ]
                                  }),
                              onPress: (function (param) {
                                  App__SignOut.signOut();
                                })
                            })),
                    hideBreakpoints: [
                      "s",
                      "m"
                    ]
                  })
            });
}

var smallLogoContainerStyle = {
  alignSelf: "center"
};

var largeLogoContainerStyle_left = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeLogoContainerStyle_position = "absolute";

var largeLogoContainerStyle_top = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeLogoContainerStyle = {
  left: largeLogoContainerStyle_left,
  position: largeLogoContainerStyle_position,
  top: largeLogoContainerStyle_top
};

var largeScreenStyles_backgroundColor = Primitives__Styling.color("primary_3");

var largeScreenStyles_flex = 1;

var largeScreenStyles_justifyContent = "center";

var largeScreenStyles_padding = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeScreenStyles = {
  backgroundColor: largeScreenStyles_backgroundColor,
  flex: largeScreenStyles_flex,
  justifyContent: largeScreenStyles_justifyContent,
  padding: largeScreenStyles_padding
};

var smallScreenStyles_backgroundColor = Primitives__Styling.color("neutral_9");

var smallScreenStyles_flex = 1;

var smallScreenStyles_justifyContent = "center";

var smallScreenStyles = {
  backgroundColor: smallScreenStyles_backgroundColor,
  flex: smallScreenStyles_flex,
  justifyContent: smallScreenStyles_justifyContent
};

function App__LoggedInUser__Onboarding(props) {
  var children = props.children;
  var match = App__LoggedInUser__OnboardingFragment.useRefetchable(props.queryRef);
  var refetch = match[1];
  var loggedInUser = match[0].loggedInUser;
  var match$1 = useUpdateOrganizationMemberHuman();
  var updateOrganizationMemberHuman = match$1[0];
  React.useEffect((function () {
          var intervalId = loggedInUser !== undefined && loggedInUser.__typename === "LoggedInUserCreating" ? Caml_option.some(setInterval((function () {
                        console.log("Refetching user data");
                        refetch(App__LoggedInUser__OnboardingFragment.makeRefetchVariables(), "store-and-network", undefined);
                      }), 5000)) : undefined;
          return (function () {
                    Core__Option.forEach(intervalId, (function (prim) {
                            clearInterval(prim);
                          }));
                  });
        }), [loggedInUser]);
  var match$2 = React.useState(function () {
        
      });
  if (loggedInUser === undefined) {
    return children;
  }
  var setError = match$2[1];
  switch (loggedInUser.__typename) {
    case "LoggedInUser" :
        var match$3 = loggedInUser.personalOrganization.loggedInOrganizationMember.organizationMember;
        var name = match$3.name;
        var loggedInOrganizationMemberId = match$3.id;
        if (name !== undefined && match$3.surname !== undefined) {
          return children;
        }
        var surname = match$3.surname;
        return JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("ss_2")),
                    style: {
                      flex: 1
                    },
                    specificStyles: {
                      s: smallScreenStyles,
                      m: smallScreenStyles,
                      l: largeScreenStyles,
                      xl: largeScreenStyles
                    },
                    children: [
                      JsxRuntime.jsx(Primitives__Group.make, {
                            specificStyles: {
                              s: smallLogoContainerStyle,
                              m: smallLogoContainerStyle,
                              l: largeLogoContainerStyle,
                              xl: largeLogoContainerStyle
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                      style: {
                                        fontWeight: "bold"
                                      },
                                      kind: "title3",
                                      children: "Decidr",
                                      color: Primitives__Styling.color("primary_1")
                                    }))
                          }),
                      JsxRuntime.jsx(App__LoggedInUser__Onboarding$SignedInAsInfo, {
                            email: match$3.email,
                            username: Utils__PersonalName.makeOpt(name, surname)
                          }),
                      JsxRuntime.jsx(Primitives__Group.make, {
                            orientation: "horizontal",
                            style: {
                              justifyContent: "center"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                      gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
                                      style: {
                                        backgroundColor: Primitives__Styling.color("neutral_9"),
                                        borderRadius: Primitives__Styling.radius("rad_6"),
                                        flex: 1,
                                        maxWidth: Caml_option.some(Primitives__Styling.dp(720)),
                                        padding: Caml_option.some(Primitives__Styling.space("ss_4"))
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(App__LoggedInUser__Onboarding$UserBasicInfo, {
                                                onNext: (function (name, surname, profilePictureSrc) {
                                                    updateOrganizationMemberHuman(loggedInOrganizationMemberId, name, surname, profilePictureSrc, (function (message) {
                                                            setError(function (param) {
                                                                  return message;
                                                                });
                                                          }), (function () {
                                                            
                                                          }));
                                                  }),
                                                initialName: Core__Option.getOr(name, ""),
                                                initialSurname: Core__Option.getOr(surname, ""),
                                                initialProfilePictureSrc: Core__Option.map(match$3.avatar, (function (avatar) {
                                                        return avatar.url;
                                                      })),
                                                updatingProfile: match$1[1],
                                                errorUpdatingProfile: match$2[0]
                                              }))
                                    }))
                          })
                    ]
                  });
    case "LoggedInUserCreating" :
        return JsxRuntime.jsx(App__LoggedInUser__FullPageCreating.make, {
                    email: loggedInUser.email
                  });
    case "LoggedInUserError" :
        return JsxRuntime.jsx(App__LoggedInUser__FullPageError.make, {
                    email: loggedInUser.email,
                    message: loggedInUser.message,
                    errorType: loggedInUser.errorType
                  });
    case "__unselected" :
        ErrorHandling__UnexpectedError.handle("Unexpected union member", "App__LoggedInUser__Onboarding", {
              typename: loggedInUser._0
            });
        return JsxRuntime.jsx(App__LoggedInUser__FullPageError.make, {
                    email: undefined,
                    message: "Unexpected client error",
                    errorType: "Unexpected"
                  });
    
  }
}

var make = App__LoggedInUser__Onboarding;

export {
  make ,
}
/* commonStyles Not a pure module */
