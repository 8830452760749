// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Storage from "../../../../storage/src/Storage.bs.js";
import * as Auth__Methods from "../../../../auth/src/methods/Auth__Methods.bs.js";
import * as Utils__Ignored from "../../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as App__UniversalUtils from "../../utils/universal/App__UniversalUtils.bs.js";
import * as App__Analytics__Utils from "../../analytics/App__Analytics__Utils.bs.js";

function signOut() {
  App__Analytics__Utils.sendAllStoredEvents();
  Utils__Ignored.promise(Auth__Methods.signOut().then(function () {
            $$Storage.clearAll();
            App__UniversalUtils.reloadApp();
          }));
}

export {
  signOut ,
}
/* Storage Not a pure module */
