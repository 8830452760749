// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Router from "solito/router";

function push(prim0, prim1, prim2, prim3, prim4) {
  prim0.push(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function pushObject(prim0, prim1, prim2, prim3, prim4) {
  prim0.push(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function replace(prim0, prim1, prim2, prim3, prim4) {
  prim0.replace(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function replaceObject(prim0, prim1, prim2, prim3, prim4) {
  prim0.replace(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

var useRouter = Router.useRouter;

function back(prim0, prim1) {
  prim0.back();
}

export {
  push ,
  pushObject ,
  replace ,
  replaceObject ,
  useRouter ,
  back ,
}
/* useRouter Not a pure module */
