// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Utils__PersonalName from "../../../../utils/src/personalName/Utils__PersonalName.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as App__Organization__WideItem from "../../organization/wideMenuItem/App__Organization__WideItem.bs.js";
import * as RescriptTamagui__Icon__User from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__User.bs.js";
import * as AppLoggedInUserWidePersonalOrganization_loggedInUser_graphql from "../../../../relay-codegen/generated/AppLoggedInUserWidePersonalOrganization_loggedInUser_graphql.bs.js";

var convertFragment = AppLoggedInUserWidePersonalOrganization_loggedInUser_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppLoggedInUserWidePersonalOrganization_loggedInUser_graphql.node, convertFragment, fRef);
}

function App__LoggedInUser__WidePersonalOrganization$Loading(props) {
  return JsxRuntime.jsx(App__Organization__WideItem.Loading.make, {
              icon: RescriptTamagui__Icon__User.make
            });
}

var Loading = {
  make: App__LoggedInUser__WidePersonalOrganization$Loading
};

function App__LoggedInUser__WidePersonalOrganization(props) {
  var onOrganizationPress = props.onOrganizationPress;
  var match = use(props.loggedInUserRef);
  var match$1 = match.personalOrganization;
  var name = match$1.name;
  var match$2 = match$1.loggedInOrganizationMember.organizationMember;
  var id = match$1.id;
  var fullName = Utils__PersonalName.makeOpt(match$2.name, match$2.surname);
  var displayName = Core__Option.orElse(fullName, match$2.email);
  var avatar = Core__Option.map(match$2.avatar, (function (param) {
          return {
                  alt: Core__Option.getOr(Core__Option.orElse(param.alt, displayName), "Logo of " + name),
                  url: param.url
                };
        }));
  var logo = Core__Option.map(match$1.logo, (function (param) {
          return {
                  alt: Core__Option.getOr(Core__Option.orElse(param.alt, displayName), "Logo of " + name),
                  url: param.url
                };
        }));
  return JsxRuntime.jsx(App__Organization__WideItem.make, {
              name: Core__Option.getOr(displayName, name),
              image: Core__Option.orElse(avatar, logo),
              onPress: (function () {
                  onOrganizationPress(id);
                }),
              fallbackIcon: RescriptTamagui__Icon__User.make
            });
}

var $$Error;

var make = App__LoggedInUser__WidePersonalOrganization;

export {
  $$Error ,
  Loading ,
  make ,
}
/* react/jsx-runtime Not a pure module */
