// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LucideIcons from "@tamagui/lucide-icons";

var make = LucideIcons.LogOut;

export {
  make ,
}
/* make Not a pure module */
