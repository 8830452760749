// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as DecidrFatalErrorBoundary from "../../../../error-boundary/src/DecidrFatalErrorBoundary.bs.js";

function App__FatalErrorBoundary(props) {
  return JsxRuntime.jsx(DecidrFatalErrorBoundary.make, {
              children: props.children
            });
}

var make = App__FatalErrorBoundary;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
