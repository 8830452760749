// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";

var smallLogoContainerStyle = {
  alignSelf: "center"
};

var largeLogoContainerStyle_left = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeLogoContainerStyle_position = "absolute";

var largeLogoContainerStyle_top = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeLogoContainerStyle = {
  left: largeLogoContainerStyle_left,
  position: largeLogoContainerStyle_position,
  top: largeLogoContainerStyle_top
};

function App__Auth__Container$MarginNeutralizer(props) {
  return JsxRuntime.jsx(ReactNative.View, {
              style: {
                marginLeft: Caml_option.some(Primitives__Styling.dp(-16)),
                marginRight: Caml_option.some(Primitives__Styling.dp(-16))
              },
              children: Caml_option.some(props.children)
            });
}

var largeScreenStyles_backgroundColor = Primitives__Styling.color("primary_4");

var largeScreenStyles_flex = 1;

var largeScreenStyles_justifyContent = "center";

var largeScreenStyles_padding = Caml_option.some(Primitives__Styling.space("ss_5"));

var largeScreenStyles = {
  backgroundColor: largeScreenStyles_backgroundColor,
  flex: largeScreenStyles_flex,
  justifyContent: largeScreenStyles_justifyContent,
  padding: largeScreenStyles_padding
};

var smallScreenStyles_backgroundColor = Primitives__Styling.color("neutral_9");

var smallScreenStyles_flex = 1;

var smallScreenStyles_justifyContent = "center";

var smallScreenStyles = {
  backgroundColor: smallScreenStyles_backgroundColor,
  flex: smallScreenStyles_flex,
  justifyContent: smallScreenStyles_justifyContent
};

function App__Auth__Container$Wrapper(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_2")),
              specificStyles: {
                s: smallScreenStyles,
                m: smallScreenStyles,
                l: largeScreenStyles,
                xl: largeScreenStyles
              },
              children: [
                JsxRuntime.jsx(Primitives__Group.make, {
                      specificStyles: {
                        s: smallLogoContainerStyle,
                        m: smallLogoContainerStyle,
                        l: largeLogoContainerStyle,
                        xl: largeLogoContainerStyle
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrImage.make, {
                                resizeMode: "contain",
                                src: "https://cdn.filestackcontent.com/XJjwvILSzma6rWgDCBrw",
                                size: {
                                  TAG: "Rect",
                                  width: Primitives__Styling.dp(190),
                                  height: Primitives__Styling.dp(50)
                                },
                                alt: "Decidr logo"
                              }))
                    }),
                JsxRuntime.jsx(Primitives__Group.make, {
                      orientation: "horizontal",
                      style: {
                        justifyContent: "center"
                      },
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var Wrapper = {
  make: App__Auth__Container$Wrapper
};

function App__Auth__Container(props) {
  return JsxRuntime.jsx(App__Auth__Container$Wrapper, {
              children: JsxRuntime.jsx(Primitives__Group.make, {
                    style: {
                      backgroundColor: Primitives__Styling.color({
                            NAME: "surface",
                            VAL: "default"
                          }),
                      borderRadius: Primitives__Styling.radius("rad_6"),
                      flex: 1,
                      maxWidth: Caml_option.some(Primitives__Styling.dp(424)),
                      padding: Caml_option.some(Primitives__Styling.space("ss_4"))
                    },
                    children: Caml_option.some(JsxRuntime.jsx(App__Auth__Container$MarginNeutralizer, {
                              children: props.children
                            }))
                  })
            });
}

var make = App__Auth__Container;

export {
  Wrapper ,
  make ,
}
/* largeLogoContainerStyle Not a pure module */
