// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../packages/node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../../packages/internal-id/src/DecidrInternalId.bs.js";
import * as RescriptGlobal__Url from "../../../../packages/rescript-global/src/url/RescriptGlobal__Url.bs.js";
import * as RescriptNext__Router from "../../../../packages/rescript-next/src/router/RescriptNext__Router.bs.js";
import * as App__Organization__Utils from "../../../../packages/app/src/organization/utils/App__Organization__Utils.bs.js";

function makePageTitle(title) {
  return title + " | Decidr";
}

function makePageTitleElement(title) {
  return makePageTitle(title);
}

function useOrganizationHeaderSetter() {
  var router = RescriptNext__Router.useRouter();
  var organizationId = Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Core__Option.map(RescriptGlobal__Url.Query.toDict(router.query)["id"], RescriptGlobal__Url.QueryParams.toJson), Core__JSON.Decode.string), DecidrInternalId.fromString), (function (organizationId) {
          return DecidrNodeId.make("Organization", organizationId);
        }));
  var match = React.useState(function () {
        return false;
      });
  var setReady = match[1];
  var ready = match[0];
  React.useLayoutEffect((function () {
          if (organizationId !== undefined && !ready) {
            App__Organization__Utils.setOrganizationHeader(Caml_option.valFromOption(organizationId));
            setReady(function (param) {
                  return true;
                });
          }
          
        }), [organizationId]);
  return {
          ready: ready
        };
}

export {
  makePageTitle ,
  makePageTitleElement ,
  useOrganizationHeaderSetter ,
}
/* react Not a pure module */
