// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptSolito__Link from "../../../rescript-solito/src/link/RescriptSolito__Link.bs.js";

var makeHrefObject = RescriptSolito__Link.makeHrefObject;

var make = RescriptSolito__Link.make;

export {
  makeHrefObject ,
  make ,
}
/* RescriptSolito__Link Not a pure module */
