// Generated by ReScript, PLEASE EDIT WITH CARE


var $$Notification = {};

var $$Location = {
  reload: (function (prim) {
      window.location.reload();
    })
};

export {
  $$Location ,
  $$Notification ,
}
/* No side effect */
