// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as AppLoggedInUserOnboardingFragment_query_graphql from "../../../../relay-codegen/generated/AppLoggedInUserOnboardingFragment_query_graphql.bs.js";
import * as AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql from "../../../../relay-codegen/generated/AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql.bs.js";

var convertFragment = AppLoggedInUserOnboardingFragment_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppLoggedInUserOnboardingFragment_query_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppLoggedInUserOnboardingFragment_query_graphql.node, convertFragment);
}

var makeRefetchVariables = AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql.Types.makeRefetchVariables;

var convertRefetchVariables = AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AppLoggedInUserOnboardingFragment_query_graphql.node, convertFragment, convertRefetchVariables, fRef);
}

var loggedInUserErrorEnum_decode = AppLoggedInUserOnboardingFragment_query_graphql.Utils.loggedInUserErrorEnum_decode;

var loggedInUserErrorEnum_fromString = AppLoggedInUserOnboardingFragment_query_graphql.Utils.loggedInUserErrorEnum_fromString;

var Types;

var Operation;

export {
  loggedInUserErrorEnum_decode ,
  loggedInUserErrorEnum_fromString ,
  Types ,
  Operation ,
  convertFragment ,
  use ,
  useOpt ,
  makeRefetchVariables ,
  convertRefetchVariables ,
  useRefetchable ,
}
/* RescriptRelay_Fragment Not a pure module */
