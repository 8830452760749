// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "solito/link";
import * as RescriptGlobal__Url from "../../../rescript-global/src/url/RescriptGlobal__Url.bs.js";

var makeHref = RescriptGlobal__Url.asString;

var makeHrefObject = RescriptGlobal__Url.asUrlObject;

var make = Link.Link;

export {
  makeHref ,
  makeHrefObject ,
  make ,
}
/* make Not a pure module */
