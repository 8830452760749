// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function makeOpt(firstName, lastName) {
  if (firstName !== undefined) {
    var exit = 0;
    if (lastName !== undefined) {
      if (firstName !== "" && lastName !== "") {
        return firstName + " " + lastName;
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2 && firstName !== "") {
      return firstName;
    }
    
  }
  if (lastName !== undefined && lastName !== "") {
    return lastName;
  }
  
}

function make(firstName, lastName, fallback) {
  return Core__Option.getOr(Core__Option.orElse(makeOpt(firstName, lastName), fallback), "No name");
}

export {
  makeOpt ,
  make ,
}
/* No side effect */
