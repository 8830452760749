/* TypeScript file generated from DecidrErrorBoundary__FatalErrorDisplay.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as DecidrErrorBoundary__FatalErrorDisplayJS from './DecidrErrorBoundary__FatalErrorDisplay.bs.js';

export type props<errorId,technicalDetails,onReload> = {
  readonly errorId?: errorId; 
  readonly technicalDetails: technicalDetails; 
  readonly onReload?: onReload
};

export const make: React.ComponentType<{
  readonly errorId?: string; 
  readonly technicalDetails: string; 
  readonly onReload?: () => void
}> = DecidrErrorBoundary__FatalErrorDisplayJS.make as any;
