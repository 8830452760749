// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";

function App__ErrorBoundary(props) {
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: props.debugContext,
              title: props.title,
              captureExceptionImpl: props.captureExceptionImpl,
              children: props.children,
              FallbackComponent: props.FallbackComponent
            });
}

var make = App__ErrorBoundary;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
