// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DecidrFatalErrorBoundary from "./DecidrFatalErrorBoundary";

var make = DecidrFatalErrorBoundary.FatalErrorBoundary;

export {
  make ,
}
/* make Not a pure module */
