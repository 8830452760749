// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../../relay-utils/src/RelayUtils.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";

function makeDataId(x) {
  return DecidrNodeId.toString(x);
}

var Disposable = RelayUtils.Disposable;

var ConnectionHandler = RelayUtils.ConnectionHandler;

var RawRelay = RelayUtils.RawRelay;

var RecordSourceSelectorProxy = RelayUtils.RecordSourceSelectorProxy;

var RecordProxy = {
  ignore: (function (prim) {
      return prim;
    })
};

var Helpers = RelayUtils.Helpers;

export {
  Disposable ,
  ConnectionHandler ,
  RawRelay ,
  RecordSourceSelectorProxy ,
  RecordProxy ,
  makeDataId ,
  Helpers ,
}
/* RelayUtils Not a pure module */
