// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

function useMedia(prim) {
  return Tamagui.useMedia();
}

export {
  useMedia ,
}
/* tamagui Not a pure module */
