// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptAwsAmplify__UiReactNative from "../../../rescript-aws-amplify/src/ui-react-native/RescriptAwsAmplify__UiReactNative.bs.js";

var Container = RescriptAwsAmplify__UiReactNative.Authenticator.Container;

var SignIn = RescriptAwsAmplify__UiReactNative.Authenticator.SignIn;

var ForgotPassword = RescriptAwsAmplify__UiReactNative.Authenticator.ForgotPassword;

var ConfirmResetPassword = RescriptAwsAmplify__UiReactNative.Authenticator.ConfirmResetPassword;

var SignUp = RescriptAwsAmplify__UiReactNative.Authenticator.SignUp;

var ConfirmSignUp = RescriptAwsAmplify__UiReactNative.Authenticator.ConfirmSignUp;

var ForceNewPassword = RescriptAwsAmplify__UiReactNative.Authenticator.ForceNewPassword;

var make = RescriptAwsAmplify__UiReactNative.Authenticator.make;

export {
  Container ,
  SignIn ,
  ForgotPassword ,
  ConfirmResetPassword ,
  SignUp ,
  ConfirmSignUp ,
  ForceNewPassword ,
  make ,
}
/* RescriptAwsAmplify__UiReactNative Not a pure module */
