// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as App__Auth__SignIn from "../auth/signIn/App__Auth__SignIn.bs.js";
import * as App__Auth__SignUp from "../auth/signUp/App__Auth__SignUp.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Auth__Authenticator from "../../../auth/src/authenticator/Auth__Authenticator.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as App__Auth__Container from "../auth/container/App__Auth__Container.bs.js";
import * as App__Auth__ConfirmSignUp from "../auth/confirmSignUp/App__Auth__ConfirmSignUp.bs.js";
import * as App__Auth__ForgotPassword from "../auth/forgotPassword/App__Auth__ForgotPassword.bs.js";
import * as App__Auth__ForceNewPassword from "../auth/forceResetPassword/App__Auth__ForceNewPassword.bs.js";
import * as App__Auth__ConfirmResetPassword from "../auth/confirmResetPassword/App__Auth__ConfirmResetPassword.bs.js";

function App__Page$Transitioning(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                justifyContent: "center",
                padding: Caml_option.some(Primitives__Styling.space("ss_2"))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "large",
                        color: Primitives__Styling.color("primary_1")
                      }))
            });
}

var passwordSettings = {
  minLength: 8,
  requireLowercase: true,
  requireNumbers: true,
  requireSpecialCharacters: true,
  requireUppercase: true
};

function App__Page(props) {
  var children = props.children;
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : "private";
  var match = Auth__Hooks.useAuth();
  var route = match.route;
  var authStatus = match.authStatus;
  var authenticatorComponent = React.useMemo((function () {
          var match = ReactNative.Platform.OS;
          if (match === "web") {
            return JsxRuntime.jsx(Auth__Authenticator.make, {
                        passwordSettings: passwordSettings,
                        loginMechanisms: ["email"],
                        components: {
                          SignIn: (function (props) {
                              return JsxRuntime.jsx(App__Auth__SignIn.make, props);
                            }),
                          ForgotPassword: (function (props) {
                              return JsxRuntime.jsx(App__Auth__ForgotPassword.make, props);
                            }),
                          ConfirmResetPassword: (function (props) {
                              return JsxRuntime.jsx(App__Auth__ConfirmResetPassword.make, props);
                            }),
                          SignUp: (function (props) {
                              return JsxRuntime.jsx(App__Auth__SignUp.make, props);
                            }),
                          ConfirmSignUp: (function (props) {
                              return JsxRuntime.jsx(App__Auth__ConfirmSignUp.make, props);
                            }),
                          ForceNewPassword: (function (props) {
                              return JsxRuntime.jsx(App__Auth__ForceNewPassword.make, props);
                            })
                        },
                        Container: (function (param) {
                            if (route === "transition") {
                              return JsxRuntime.jsx(App__Auth__Container.Wrapper.make, {
                                          children: JsxRuntime.jsx(App__Page$Transitioning, {})
                                        });
                            } else {
                              return JsxRuntime.jsx(App__Auth__Container.make, {
                                          children: param.children
                                        });
                            }
                          })
                      });
          }
          var tmp = route === "transition" ? (function (param) {
                return JsxRuntime.jsx(App__Page$Transitioning, {});
              }) : undefined;
          return JsxRuntime.jsx(Auth__Authenticator.make, {
                      passwordSettings: passwordSettings,
                      loginMechanisms: ["email"],
                      components: {
                        SignIn: (function (props) {
                            return JsxRuntime.jsx(App__Auth__SignIn.make, props);
                          }),
                        ForgotPassword: (function (props) {
                            return JsxRuntime.jsx(App__Auth__ForgotPassword.make, props);
                          }),
                        ConfirmResetPassword: (function (props) {
                            return JsxRuntime.jsx(App__Auth__ConfirmResetPassword.make, props);
                          }),
                        SignUp: (function (props) {
                            return JsxRuntime.jsx(App__Auth__SignUp.make, props);
                          }),
                        ConfirmSignUp: (function (props) {
                            return JsxRuntime.jsx(App__Auth__ConfirmSignUp.make, props);
                          }),
                        ForceNewPassword: (function (props) {
                            return JsxRuntime.jsx(App__Auth__ForceNewPassword.make, props);
                          })
                      },
                      Container: tmp
                    });
        }), [route]);
  if (kind === "private") {
    if (authStatus === "configuring") {
      return null;
    } else if (authStatus === "unauthenticated") {
      return authenticatorComponent;
    } else {
      return children;
    }
  } else {
    return children;
  }
}

var make = App__Page;

export {
  make ,
}
/* react Not a pure module */
