// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Solito from "solito";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";

var param = Solito.createParam();

function use(paramName) {
  return param.useParam(paramName);
}

var param$1 = Solito.createParam();

function use$1(paramName) {
  return param$1.useParam(paramName, {
              parse: (function (param) {
                  if (param !== undefined) {
                    return Core__JSON.Decode.bool(JSON.parse(param));
                  }
                  
                })
            });
}

function Make(funarg) {
  var param = Solito.createParam();
  var useParam = function (key, config) {
    return param.useParam(key, config);
  };
  return {
          useParam: useParam
        };
}

var $$String = {
  use: use
};

var Bool = {
  use: use$1
};

export {
  Make ,
  $$String ,
  Bool ,
}
/* param Not a pure module */
