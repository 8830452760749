// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as App__LoggedInUser__Onboarding from "./App__LoggedInUser__Onboarding.bs.js";
import * as AppLoggedInUserConnectedOnboardingQuery_graphql from "../../../../relay-codegen/generated/AppLoggedInUserConnectedOnboardingQuery_graphql.bs.js";

var convertVariables = AppLoggedInUserConnectedOnboardingQuery_graphql.Internal.convertVariables;

var convertResponse = AppLoggedInUserConnectedOnboardingQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, AppLoggedInUserConnectedOnboardingQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, AppLoggedInUserConnectedOnboardingQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(AppLoggedInUserConnectedOnboardingQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(AppLoggedInUserConnectedOnboardingQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(AppLoggedInUserConnectedOnboardingQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(AppLoggedInUserConnectedOnboardingQuery_graphql.node, convertVariables);

function App__LoggedInUser__ConnectedOnboarding(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use({
        unauthenticated: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(App__LoggedInUser__Onboarding.make, {
              queryRef: match$1.fragmentRefs,
              children: props.children
            });
}

var make = App__LoggedInUser__ConnectedOnboarding;

export {
  make ,
}
/* use Not a pure module */
