// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrImage from "../../../primitives/src/image/Primitives__DecidrImage.bs.js";

function App__Logotype(props) {
  return JsxRuntime.jsx(Primitives__DecidrImage.make, {
              resizeMode: "contain",
              src: "https://cdn.filestackcontent.com/XJjwvILSzma6rWgDCBrw",
              size: {
                TAG: "Rect",
                width: Primitives__Styling.dp(24 * 3.8),
                height: Primitives__Styling.dp(24)
              },
              alt: "Decidr logo"
            });
}

var make = App__Logotype;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
