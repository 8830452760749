// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNativeDrawerLayout from "react-native-drawer-layout";

var make = ReactNativeDrawerLayout.Drawer;

export {
  make ,
}
/* make Not a pure module */
