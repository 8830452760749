// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Ui__SimpleOneInputModal from "../../../spaces/src/ui/simpleOneInputModal/Spaces__Ui__SimpleOneInputModal.bs.js";

function App__SimpleOneInputModal(props) {
  return JsxRuntime.jsx(Spaces__Ui__SimpleOneInputModal.make, {
              show: props.show,
              onClose: props.onClose,
              onButtonPress: props.onButtonPress,
              label: props.label,
              placeholder: props.placeholder,
              loading: props.loading,
              initialValue: props.initialValue,
              title: props.title,
              buttonTitle: props.buttonTitle
            });
}

var make = App__SimpleOneInputModal;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
