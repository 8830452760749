import { Amplify } from "aws-amplify";
import {
  baseAmplifyConfig,
  defaultAmplifyLibraryOptions,
} from "../../amplifyConfig";

export const setOrganizationHeader = (organizationId: string) => {
  Amplify.configure(baseAmplifyConfig, {
    ...defaultAmplifyLibraryOptions,
    API: {
      ...defaultAmplifyLibraryOptions?.API,
      GraphQL: {
        ...defaultAmplifyLibraryOptions?.API?.GraphQL,
        headers: () =>
          Promise.resolve({
            "x-decidr-auth": JSON.stringify({
              organizationId: organizationId,
            }),
          }),
      },
    },
  });
};

export const unsetOrganizationHeader = () => {
  Amplify.configure(baseAmplifyConfig, {
    ...defaultAmplifyLibraryOptions,
    API: {
      ...defaultAmplifyLibraryOptions?.API,
      GraphQL: {
        ...defaultAmplifyLibraryOptions?.API?.GraphQL,
        headers: () =>
          Promise.resolve({
            "x-decidr-auth": JSON.stringify({
              organizationId: null,
            }),
          }),
      },
    },
  });
};
