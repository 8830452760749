// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_loggedInUser(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["LoggedInUser"]);
}

var fragmentConverter = {"__root":{"loggedInUser_LoggedInUser":{"f":""},"loggedInUser":{"u":"fragment_loggedInUser"}}};

var fragmentConverterMap = {
  fragment_loggedInUser: unwrap_fragment_loggedInUser
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLoggedInUserWideMenu_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "loggedInUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AppLoggedInUserWidePersonalOrganization_loggedInUser"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AppLoggedInUserSharedMenuFragment_loggedInUser"
            }
          ],
          "type": "LoggedInUser",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

var wrap_fragment_loggedInUser = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_loggedInUser ,
  wrap_fragment_loggedInUser ,
  Internal ,
  Utils ,
  node ,
}
/* RescriptRelay Not a pure module */
