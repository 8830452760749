// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as App__Organization__WideItem from "./App__Organization__WideItem.bs.js";
import * as AppOrganizationWideMenuItem_organization_graphql from "../../../../relay-codegen/generated/AppOrganizationWideMenuItem_organization_graphql.bs.js";

var convertFragment = AppOrganizationWideMenuItem_organization_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppOrganizationWideMenuItem_organization_graphql.node, convertFragment, fRef);
}

function App__Organization__WideMenuItem(props) {
  var onOrganizationPress = props.onOrganizationPress;
  var match = use(props.organizationRef);
  var name = match.name;
  var id = match.id;
  return JsxRuntime.jsx(App__Organization__WideItem.make, {
              name: name,
              image: Core__Option.map(match.logo, (function (param) {
                      return {
                              alt: Core__Option.getOr(param.alt, "Logo of " + name),
                              url: param.url
                            };
                    })),
              onPress: (function () {
                  onOrganizationPress(id);
                })
            });
}

var $$Error;

var Loading;

var make = App__Organization__WideMenuItem;

export {
  $$Error ,
  Loading ,
  make ,
}
/* react/jsx-runtime Not a pure module */
