// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__Separator from "../../../rescript-tamagui/src/separator/RescriptTamagui__Separator.bs.js";

function Primitives__Divider(props) {
  var __orientation = props.orientation;
  var __size = props.size;
  var __color = props.color;
  var color = __color !== undefined ? __color : Primitives__Styling.color({
          NAME: "border",
          VAL: {
            NAME: "neutral",
            VAL: "default"
          }
        });
  var size = __size !== undefined ? __size : 1;
  var orientation = __orientation !== undefined ? __orientation : "horizontal";
  return JsxRuntime.jsx(RescriptTamagui__Separator.make, {
              style: {
                borderBottomWidth: size,
                height: orientation === "vertical" ? Caml_option.some(Primitives__Styling.pct(100)) : undefined,
                width: orientation === "horizontal" ? Caml_option.some(Primitives__Styling.pct(100)) : undefined
              },
              vertical: orientation === "vertical",
              boc: color
            });
}

var make = Primitives__Divider;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
