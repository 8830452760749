// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UiReactNative from "@aws-amplify/ui-react-native";

var AuthEventData = {};

var Container = {};

var CommonProps = {};

var SignIn = {};

var ForgotPassword = {};

var ConfirmResetPassword = {};

var SignUp = {};

var ConfirmSignUp = {};

var ForceNewPassword = {};

var Authenticator = {
  AuthEventData: AuthEventData,
  Container: Container,
  CommonProps: CommonProps,
  SignIn: SignIn,
  ForgotPassword: ForgotPassword,
  ConfirmResetPassword: ConfirmResetPassword,
  SignUp: SignUp,
  ConfirmSignUp: ConfirmSignUp,
  ForceNewPassword: ForceNewPassword,
  make: UiReactNative.Authenticator
};

export {
  Authenticator ,
}
/* Authenticator Not a pure module */
