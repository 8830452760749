// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ExpoUpdates from "expo-updates";

function reloadAsync(prim) {
  return ExpoUpdates.reloadAsync();
}

export {
  reloadAsync ,
}
/* expo-updates Not a pure module */
