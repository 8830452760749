// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptTamagui__Hooks from "../../../rescript-tamagui/src/hooks/RescriptTamagui__Hooks.bs.js";

var useMedia = RescriptTamagui__Hooks.useMedia;

export {
  useMedia ,
}
/* RescriptTamagui__Hooks Not a pure module */
