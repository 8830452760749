// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Utils__StringUtils from "../../../../utils/src/string/Utils__StringUtils.bs.js";
import * as App__OrganizationEntity from "../App__OrganizationEntity.bs.js";
import * as App__SimpleOneInputModal from "../../simpleCreateModal/App__SimpleOneInputModal.bs.js";
import * as Spaces__Organization__Mutations from "../../../../spaces/src/organization/Spaces__Organization__Mutations.bs.js";

function App__Organization__CreateModal(props) {
  var onCreated = props.onCreated;
  var loggedInUserDataId = props.loggedInUserDataId;
  var match = Spaces__Organization__Mutations.useCreate();
  var createOrganization = match[0];
  return JsxRuntime.jsx(App__SimpleOneInputModal.make, {
              show: props.show,
              onClose: props.onClose,
              onButtonPress: (function (value, onError, onSuccess) {
                  createOrganization(value, Utils__StringUtils.toKebabCase(value), (function (message, param) {
                          onError(message);
                        }), (function (id) {
                          onSuccess();
                          onCreated(id);
                        }), App__OrganizationEntity.collectAllRelayConnections(loggedInUserDataId));
                }),
              label: "New organization name",
              placeholder: "My organization",
              loading: match[1],
              initialValue: "New organization",
              title: "Create organization"
            });
}

var make = App__Organization__CreateModal;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
