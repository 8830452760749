// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayRuntime from "relay-runtime";

function collectAllRelayConnections(loggedInUserDataId) {
  return [RelayRuntime.ConnectionHandler.getConnectionID(loggedInUserDataId, "AppLoggedInUserSharedMenuFragment_loggedInUser_organizations", undefined)];
}

export {
  collectAllRelayConnections ,
}
/* relay-runtime Not a pure module */
