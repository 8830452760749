// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql from "./AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql.bs.js";

var Types = {};

function unwrap_fragment_loggedInUser(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "LoggedInUser",
              "LoggedInUserCreating",
              "LoggedInUserError"
            ]);
}

var fragmentConverter = {"__root":{"loggedInUser_LoggedInUser_personalOrganization_loggedInOrganizationMember_organizationMember_id":{"c":"DecidrNodeId.RelayAppSync"},"loggedInUser":{"u":"fragment_loggedInUser"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_loggedInUser: unwrap_fragment_loggedInUser
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function loggedInUserErrorEnum_decode($$enum) {
  if ($$enum === "NotFound" || $$enum === "Internal" || $$enum === "Unexpected") {
    return $$enum;
  }
  
}

function loggedInUserErrorEnum_fromString(str) {
  return loggedInUserErrorEnum_decode(str);
}

var Utils = {
  loggedInUserErrorEnum_decode: loggedInUserErrorEnum_decode,
  loggedInUserErrorEnum_fromString: loggedInUserErrorEnum_fromString
};

function makeNode(rescript_graphql_node_AppLoggedInUserOnboardingFragmentRefetchableQuery) {
  return ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_AppLoggedInUserOnboardingFragmentRefetchableQuery
    }
  },
  "name": "AppLoggedInUserOnboardingFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "loggedInUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "personalOrganization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoggedInOrganizationMember",
                  "kind": "LinkedField",
                  "name": "loggedInOrganizationMember",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrganizationMember",
                      "kind": "LinkedField",
                      "name": "organizationMember",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "surname",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageResource",
                          "kind": "LinkedField",
                          "name": "avatar",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "alt",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "type": "LoggedInUser",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "LoggedInUserCreating",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": "errorType",
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "type": "LoggedInUserError",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());
}

var node = makeNode(AppLoggedInUserOnboardingFragmentRefetchableQuery_graphql.node);

var wrap_fragment_loggedInUser = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_loggedInUser ,
  wrap_fragment_loggedInUser ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
