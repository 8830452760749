// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptAwsAmplify__Auth from "../../../rescript-aws-amplify/src/auth/RescriptAwsAmplify__Auth.bs.js";

var signOut = RescriptAwsAmplify__Auth.signOut;

export {
  signOut ,
}
/* RescriptAwsAmplify__Auth Not a pure module */
