// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as Utils__Ignored from "../../utils/src/ignored/Utils__Ignored.bs.js";
import * as ExpoClipboard from "expo-clipboard";
import * as Primitives__Group from "../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../primitives/src/button/Primitives__Button.bs.js";
import * as App__UniversalUtils from "../../app/src/utils/universal/App__UniversalUtils.bs.js";
import * as Primitives__Styling from "../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";

function DecidrErrorBoundary__FatalErrorDisplay$Container(props) {
  var children = props.children;
  if (ReactNative.Platform.OS === "web") {
    return children;
  } else {
    return JsxRuntime.jsx(ReactNativeSafeAreaContext.SafeAreaView, {
                style: {
                  flex: 1
                },
                children: Caml_option.some(children)
              });
  }
}

function DecidrErrorBoundary__FatalErrorDisplay(props) {
  var onReload = props.onReload;
  var technicalDetails = props.technicalDetails;
  return JsxRuntime.jsx(DecidrErrorBoundary__FatalErrorDisplay$Container, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("ss_3")),
                    style: {
                      alignItems: "center",
                      flex: 1,
                      justifyContent: "center",
                      padding: Caml_option.some(Primitives__Styling.space("ss_2"))
                    },
                    children: [
                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                            style: {
                              fontWeight: "bold",
                              textAlign: "center"
                            },
                            kind: "title3",
                            children: "Decidr",
                            color: Primitives__Styling.color("primary_1")
                          }),
                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                            style: {
                              fontWeight: "bold",
                              textAlign: "center"
                            },
                            kind: "body1",
                            children: "Oops!"
                          }),
                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                            style: {
                              textAlign: "center"
                            },
                            children: "Your app has crashed. Please try reloading the app."
                          }),
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            children: [
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      textAlign: "center"
                                    },
                                    children: "We couldn't load this page because an error has occurred. Please, try again."
                                  }),
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      textAlign: "center"
                                    },
                                    children: "Our team is notified of this error. We are working on fixing it."
                                  })
                            ]
                          }),
                      ReactUtils__SimpleReact.renderIfSome(props.errorId, (function (errorId) {
                              return JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                          children: [
                                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                  children: "Error ID: "
                                                }),
                                            errorId
                                          ]
                                        });
                            })),
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                            style: {
                              justifyContent: "center",
                              width: Caml_option.some(Primitives__Styling.dp(320))
                            },
                            specificStyles: {
                              s: {
                                flexDirection: "column"
                              },
                              m: {
                                flexDirection: "column"
                              },
                              l: {
                                flexDirection: "row"
                              },
                              xl: {
                                flexDirection: "row"
                              }
                            },
                            children: [
                              JsxRuntime.jsx(Primitives__Button.make, {
                                    title: "Copy error",
                                    style: {
                                      flex: 1
                                    },
                                    onPress: (function (param) {
                                        Utils__Ignored.promiseWithPayload(ExpoClipboard.setStringAsync(technicalDetails, undefined));
                                      }),
                                    kind: "secondary"
                                  }),
                              JsxRuntime.jsx(Primitives__Button.make, {
                                    title: "Reload the app",
                                    style: {
                                      flex: 1
                                    },
                                    onPress: (function (param) {
                                        App__UniversalUtils.reloadApp();
                                        Core__Option.forEach(onReload, (function (onReload) {
                                                onReload();
                                              }));
                                      })
                                  })
                            ]
                          })
                    ]
                  })
            });
}

var make = DecidrErrorBoundary__FatalErrorDisplay;

export {
  make ,
}
/* react-native Not a pure module */
