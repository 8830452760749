// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App__SignOut from "../../auth/signOut/App__SignOut.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as App__UniversalUtils from "../../utils/universal/App__UniversalUtils.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";

function App__LoggedInUser__FullPageError(props) {
  var errorType = props.errorType;
  var message;
  if (errorType === "NotFound" || errorType === "Internal" || errorType === "Unexpected") {
    message = props.message;
  } else {
    ErrorHandling__UnexpectedError.handle("FutureAddedValue error occurred", "App__LoggedInUser__FullPageError", {
          typename: errorType
        });
    message = undefined;
  }
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_3")),
              style: {
                alignItems: "center",
                flex: 1,
                justifyContent: "center",
                padding: Caml_option.some(Primitives__Styling.space("ss_2"))
              },
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: "bold",
                        textAlign: "center"
                      },
                      kind: "title3",
                      children: "Decidr",
                      color: Primitives__Styling.color("primary_1")
                    }),
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: "bold",
                        textAlign: "center"
                      },
                      kind: "body1",
                      children: "Oops!"
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "We couldn't load this page because an error has occurred. Please, try again."
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Our team is notified of this error. We are working on fixing it."
                            })
                      ]
                    }),
                ReactUtils__SimpleReact.renderIfSome(message, (function (message) {
                        return JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: [
                                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                                            style: {
                                              fontWeight: 400
                                            },
                                            children: "Reason: "
                                          }),
                                      message
                                    ]
                                  });
                      })),
                ReactUtils__SimpleReact.renderIfSome(props.email, (function (email) {
                        return JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: [
                                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                                            style: {
                                              fontWeight: 400
                                            },
                                            children: "You are logged in as: "
                                          }),
                                      email
                                    ]
                                  });
                      })),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      style: {
                        justifyContent: "center",
                        width: Caml_option.some(Primitives__Styling.dp(320))
                      },
                      specificStyles: {
                        s: {
                          flexDirection: "column"
                        },
                        m: {
                          flexDirection: "column"
                        },
                        l: {
                          flexDirection: "row"
                        },
                        xl: {
                          flexDirection: "row"
                        }
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__Button.make, {
                              title: "Reload the app",
                              style: {
                                flex: 1
                              },
                              onPress: (function (param) {
                                  App__UniversalUtils.reloadApp();
                                }),
                              kind: "secondary"
                            }),
                        JsxRuntime.jsx(Primitives__Button.make, {
                              title: "Sign out",
                              style: {
                                flex: 1
                              },
                              onPress: (function (param) {
                                  App__SignOut.signOut();
                                })
                            })
                      ]
                    })
              ]
            });
}

var make = App__LoggedInUser__FullPageError;

export {
  make ,
}
/* App__SignOut Not a pure module */
