// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Router from "next/router";

function useRouter(prim) {
  return Router.useRouter();
}

export {
  useRouter ,
}
/* next/router Not a pure module */
