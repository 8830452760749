// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as RescriptSolito__Router from "../../../rescript-solito/src/router/RescriptSolito__Router.bs.js";

function canGoBack(navigation) {
  var match = ReactNative.Platform.OS;
  if (match === "web") {
    return "noItsWeb";
  } else if (Core__Option.getOr(Core__Option.map((navigation == null) ? undefined : Caml_option.some(navigation), (function (__x) {
                return __x.canGoBack();
              })), false)) {
    return "yes";
  } else {
    return "no";
  }
}

var useRouter = RescriptSolito__Router.useRouter;

var pushString = RescriptSolito__Router.push;

var pushObject = RescriptSolito__Router.pushObject;

var replaceString = RescriptSolito__Router.replace;

var replaceObject = RescriptSolito__Router.replaceObject;

var back = RescriptSolito__Router.back;

export {
  useRouter ,
  pushString ,
  pushObject ,
  replaceString ,
  replaceObject ,
  canGoBack ,
  back ,
}
/* react-native Not a pure module */
