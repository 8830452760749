// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptSolito__Params from "../../../rescript-solito/src/params/RescriptSolito__Params.bs.js";

function useString(param) {
  var match = RescriptSolito__Params.$$String.use(param);
  var setParam = match[1];
  var setParam$1 = function (param, options) {
    setParam(param, options);
  };
  return [
          match[0],
          setParam$1
        ];
}

function useBool(param) {
  var match = RescriptSolito__Params.Bool.use(param);
  var setParam = match[1];
  var setParam$1 = function (param, options) {
    setParam(param, options);
  };
  return [
          match[0],
          setParam$1
        ];
}

export {
  useString ,
  useBool ,
}
/* RescriptSolito__Params Not a pure module */
