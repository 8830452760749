// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as Utils__Ignored from "../../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as RescriptExpo__Updates from "../../../../rescript-expo/src/updates/RescriptExpo__Updates.bs.js";
import * as RescriptGlobal__Window from "../../../../rescript-global/src/window/RescriptGlobal__Window.bs.js";

function reloadApp() {
  if (ReactNative.Platform.OS === "web") {
    if (Core__Option.isSome(Caml_option.undefined_to_opt(typeof window === "undefined" ? undefined : window))) {
      return RescriptGlobal__Window.$$Location.reload();
    } else {
      return ;
    }
  } else {
    return Utils__Ignored.promise(RescriptExpo__Updates.reloadAsync());
  }
}

function request() {
  if (ReactNative.Platform.OS !== "web") {
    return ;
  }
  var notification = window.Notification;
  if (notification === undefined) {
    return ;
  }
  var match = notification.permission;
  if (match === "default") {
    return Utils__Ignored.promise(notification.requestPermission().then(function (param) {
                    
                  }));
  }
  
}

var $$Notification = {
  request: request
};

var $$Permissions = {
  $$Notification: $$Notification
};

export {
  reloadApp ,
  $$Permissions ,
}
/* react-native Not a pure module */
