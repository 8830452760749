// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as JsxRuntime from "react/jsx-runtime";

var nativeBreakpoints = [
  "s",
  "m"
];

function getProps(element) {
  return Core__Option.getOr(Core__Option.flatMap(Core__JSON.Decode.object(element), (function (__x) {
                    return __x["props"];
                  })), {});
}

function Primitives__NativeConditionalRender$Native(props) {
  return React.Children.map(props.children, (function (child) {
                var hideBreakpoints = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(getProps(child)), (function (__x) {
                                    return __x["hideBreakpoints"];
                                  })), Core__JSON.Decode.array), (function (__x) {
                            return Core__Array.filterMap(__x, Core__JSON.Decode.string);
                          })), []);
                if (hideBreakpoints.some(function (hbp) {
                        return nativeBreakpoints.some(function (nbp) {
                                    return nbp === hbp;
                                  });
                      })) {
                  return null;
                } else {
                  return child;
                }
              }));
}

function Primitives__NativeConditionalRender(props) {
  var children = props.children;
  var match = ReactNative.Platform.OS;
  if (match === "web") {
    return children;
  } else {
    return JsxRuntime.jsx(Primitives__NativeConditionalRender$Native, {
                children: children
              });
  }
}

var make = Primitives__NativeConditionalRender;

export {
  make ,
}
/* react Not a pure module */
