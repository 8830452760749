// Generated by ReScript, PLEASE EDIT WITH CARE

import * as WebUtils from "./webUtils";

function make(prim) {
  return WebUtils.makeQueryParam(prim.VAL);
}

function isNull(prim) {
  return WebUtils.isQueryParamNull(prim);
}

function QueryParams_getString(prim) {
  return prim;
}

function QueryParams_toJson(prim) {
  return prim;
}

var QueryParams = {
  make: make,
  isNull: isNull,
  getString: QueryParams_getString,
  toJson: QueryParams_toJson
};

function make$1(params) {
  return Object.fromEntries(params);
}

function Query_asString(prim) {
  return prim;
}

function Query_toDict(prim) {
  return prim;
}

var Query = {
  asString: Query_asString,
  toDict: Query_toDict,
  make: make$1
};

function makeUrlObject(auth, hash, host, hostname, href, pathname, protocol, search, slashes, port, query, param) {
  return {
          auth: auth,
          hash: hash,
          host: host,
          hostname: hostname,
          href: href,
          pathname: pathname,
          protocol: protocol,
          search: search,
          slashes: slashes,
          port: port,
          query: query
        };
}

function Locale_asString(prim) {
  return prim;
}

function Locale_asBool(prim) {
  return prim;
}

var Locale = {
  asString: Locale_asString,
  asBool: Locale_asBool
};

function Port_asString(prim) {
  return prim;
}

function Port_asInt(prim) {
  return prim;
}

var Port = {
  asString: Port_asString,
  asInt: Port_asInt
};

var $$URLSearchParams = {
  get: (function (prim0, prim1) {
      return prim0.get(prim1);
    })
};

function make$2(prim) {
  return new URL(prim);
}

function makeWithBase(prim0, prim1) {
  return new URL(prim0, prim1);
}

function asString(prim) {
  return prim;
}

function asUrlObject(prim) {
  return prim;
}

function getHash(prim) {
  return prim.hash;
}

function getHost(prim) {
  return prim.host;
}

function getHostname(prim) {
  return prim.hostname;
}

function getHref(prim) {
  return prim.href;
}

function getOrigin(prim) {
  return prim.origin;
}

function getPassword(prim) {
  return prim.password;
}

function getPathname(prim) {
  return prim.pathname;
}

function getPort(prim) {
  return prim.port;
}

function getProtocol(prim) {
  return prim.protocol;
}

function getSearch(prim) {
  return prim.search;
}

function getSearchParams(prim) {
  return prim.searchParams;
}

function getUsername(prim) {
  return prim.username;
}

export {
  Locale ,
  Port ,
  QueryParams ,
  Query ,
  $$URLSearchParams ,
  make$2 as make,
  makeWithBase ,
  asString ,
  asUrlObject ,
  makeUrlObject ,
  getHash ,
  getHost ,
  getHostname ,
  getHref ,
  getOrigin ,
  getPassword ,
  getPathname ,
  getPort ,
  getProtocol ,
  getSearch ,
  getSearchParams ,
  getUsername ,
}
/* ./webUtils Not a pure module */
