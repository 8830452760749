// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";

function makeParams(params) {
  return Core__Array.filterMap(params, (function (x) {
                return x;
              }));
}

export {
  makeParams ,
}
/* No side effect */
