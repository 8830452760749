// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesOrganizationMutationsCreateBotMutation_graphql from "../../../relay-codegen/generated/SpacesOrganizationMutationsCreateBotMutation_graphql.bs.js";
import * as SpacesOrganizationMutationsLeaveOrganizationMutation_graphql from "../../../relay-codegen/generated/SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.bs.js";
import * as SpacesOrganizationMutations_createOrganizationMutation_graphql from "../../../relay-codegen/generated/SpacesOrganizationMutations_createOrganizationMutation_graphql.bs.js";
import * as SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql from "../../../relay-codegen/generated/SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.bs.js";

var convertVariables = SpacesOrganizationMutations_createOrganizationMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesOrganizationMutations_createOrganizationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesOrganizationMutations_createOrganizationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesOrganizationMutations_createOrganizationMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesOrganizationMutations_createOrganizationMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreate() {
  var match = use();
  var createOrganization = match[0];
  var handleCreate = function (name, param, onError, onCompleted, relayConnectionsToUpdate) {
    createOrganization({
          name: name,
          relayConnectionsToUpdate: relayConnectionsToUpdate
        }, undefined, undefined, undefined, (function (param, errors) {
            var organization = param.createOrganization.organization;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating organization", "Spaces__Organization__Mutations useCreate", (function (message, reason) {
                    onError(message, reason);
                  }), (function () {
                    onCompleted(organization.id);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating organization", "Spaces__Organization__Mutations useCreate", (function (message, reason) {
                    onError(message, reason);
                  }));
          }), undefined);
  };
  return [
          handleCreate,
          match[1]
        ];
}

var convertVariables$1 = SpacesOrganizationMutationsCreateBotMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesOrganizationMutationsCreateBotMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesOrganizationMutationsCreateBotMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesOrganizationMutationsCreateBotMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesOrganizationMutationsCreateBotMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useCreateBot() {
  var match = use$1();
  var createBot = match[0];
  var handleCreateBot = function (organizationId, name, model, templateVersionId, onError, onCompleted, connectionsToUpdateOpt) {
    var connectionsToUpdate = connectionsToUpdateOpt !== undefined ? connectionsToUpdateOpt : [];
    createBot({
          model: model,
          name: name,
          organizationId: organizationId,
          relayConnectionsToUpdate: connectionsToUpdate,
          templateVersionId: templateVersionId
        }, undefined, undefined, (function (store, response) {
            Core__Option.forEach(Core__Option.flatMap(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(organizationId))), (function (organizationRP) {
                        return RelayUtils.RecordProxy.getLinkedRecord(organizationRP, "aiMembers", {
                                    first: 200
                                  });
                      })), (function (aiMembersConnection) {
                    RelayUtils.Helpers.addUniqueEdgeToConnection(store, aiMembersConnection, response.createOrganizationMemberBot.bot.__id, "OrganizationMemberEdge", "last");
                  }));
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating bot", "Spaces__Organization__Mutations useCreateBot", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating bot", "Spaces__Organization__Mutations useCreateBot", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleCreateBot,
          match[1]
        ];
}

var convertVariables$2 = SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesOrganizationMutationsRemoveOrganizationMemberFromOrganizationMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useRemoveOrganizationMember() {
  var match = use$2();
  var removeOrganizationMember = match[0];
  var handleRemoveOrganizationMember = function (organizationMemberId, relayConnectionsToUpdate, onError, onCompleted) {
    removeOrganizationMember({
          organizationMemberId: organizationMemberId,
          relayConnectionsToUpdate: Core__Option.getOr(relayConnectionsToUpdate, [])
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error removing member from organization", "Spaces__Organization__Mutations useRemoveOrganizationMember", onError, (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error removing member from organization", "Spaces__Organization__Mutations useRemoveOrganizationMember", onError);
          }), undefined);
  };
  return [
          handleRemoveOrganizationMember,
          match[1]
        ];
}

var convertVariables$3 = SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesOrganizationMutationsLeaveOrganizationMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function useLeaveOrganization() {
  var match = use$3();
  var leaveOrganization = match[0];
  var handleLeaveOrganization = function (relayConnectionsToUpdate, onError, onCompleted) {
    leaveOrganization({
          relayConnectionsToUpdate: Core__Option.getOr(relayConnectionsToUpdate, [])
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error leaving organization", "Spaces__Organization__Mutations useLeaveOrganization", onError, (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error leaving organization", "Spaces__Organization__Mutations useLeaveOrganization", onError);
          }), undefined);
  };
  return [
          handleLeaveOrganization,
          match[1]
        ];
}

export {
  useCreate ,
  useCreateBot ,
  useRemoveOrganizationMember ,
  useLeaveOrganization ,
}
/*  Not a pure module */
