// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";

function make(param) {
  var resendCode = param.resendCode;
  var handleSubmit = param.handleSubmit;
  var receivedError = param.error;
  var match = Auth__Hooks.useAuth();
  var toSignIn = match.toSignIn;
  var match$1 = Primitives__Input.use("", undefined, undefined);
  var code = match$1.value;
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  React.useEffect((function () {
          Core__Option.forEach(receivedError, (function (error) {
                  var match = error.trim();
                  if (match === "") {
                    return ;
                  } else {
                    return setError(function (param) {
                                return "An unknown error has occurred";
                              });
                  }
                }));
        }), [receivedError]);
  var handleSubmit$1 = React.useCallback((function () {
          var code$1 = code.trim();
          if (code$1 === "") {
            return setError(function (param) {
                        return "Confirmation code can't be empty";
                      });
          } else {
            setError(function (param) {
                  
                });
            return handleSubmit({
                        confirmation_code: code$1
                      });
          }
        }), [
        code,
        handleSubmit
      ]);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "title3",
                              children: "Verify your account"
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              kind: "body2",
                              children: "Please verify your account by typing the code sent to your email address."
                            })
                      ]
                    }),
                ReactUtils__SimpleReact.renderIfSome(match$2[0], (function (error) {
                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500,
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(error),
                                    color: Primitives__Styling.color("error_1")
                                  });
                      })),
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: code,
                      onChange: match$1.onChange,
                      returnKeyType: "done",
                      placeholder: "Type the code here"
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Finish",
                      onPress: (function (param) {
                          handleSubmit$1();
                        }),
                      busy: param.isPending,
                      scaleOnHover: true
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "baseline",
                        flexWrap: "wrap",
                        justifyContent: "center"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Didn't receive the code?"
                            }),
                        JsxRuntime.jsx(Primitives__TextButton.make, {
                              title: "Resend code",
                              onPress: (function (param) {
                                  resendCode(undefined);
                                })
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "baseline",
                        justifyContent: "center"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                textAlign: "center"
                              },
                              children: "Go back to"
                            }),
                        JsxRuntime.jsx(Primitives__TextButton.make, {
                              title: "Sign in",
                              onPress: (function (param) {
                                  toSignIn();
                                })
                            })
                      ]
                    })
              ]
            });
}

export {
  make ,
}
/* react Not a pure module */
