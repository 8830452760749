// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";

var Types = {};

var fragmentConverter = {"__root":{"organizations_edges_node_id":{"c":"DecidrNodeId.RelayAppSync"},"organizations_edges_node":{"f":""}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function makeConnectionId(connectionParentDataId) {
  return RelayRuntime.ConnectionHandler.getConnectionID(connectionParentDataId, "AppLoggedInUserSharedMenuFragment_loggedInUser_organizations", undefined);
}

function getConnectionNodes(connection) {
  return Belt_Array.keepMap(connection.edges, (function (edge) {
                return edge.node;
              }));
}

var Utils = {
  getConnectionNodes: getConnectionNodes
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "organizations"
        ]
      }
    ]
  },
  "name": "AppLoggedInUserSharedMenuFragment_loggedInUser",
  "selections": [
    {
      "alias": "organizations",
      "args": null,
      "concreteType": "OrganizationConnection",
      "kind": "LinkedField",
      "name": "__AppLoggedInUserSharedMenuFragment_loggedInUser_organizations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AppOrganizationNarrowMenuItem_organization"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AppOrganizationWideMenuItem_organization"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "LoggedInUser",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  makeConnectionId ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
