type param =
  | string
  | number
  | boolean
  | ReadonlyArray<string>
  | ReadonlyArray<number>
  | ReadonlyArray<boolean>
  | null;

// Needed for better binding
// With this we can make binding to this fn on which we can `@unwrap` poly-variant
export const makeQueryParam = (param: param) => param;

export const isQueryParamNull = (param: param) => param == null;
