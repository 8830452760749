// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App__SignOut from "../../auth/signOut/App__SignOut.bs.js";
import * as ReactNative from "react-native";
import * as App__Logotype from "../../logo/App__Logotype.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as App__ErrorBoundary from "../../error/boundary/App__ErrorBoundary.bs.js";
import * as Primitives__Divider from "../../../../primitives/src/divider/Primitives__Divider.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as App__Organization__WideItem from "../../organization/wideMenuItem/App__Organization__WideItem.bs.js";
import * as RescriptTamagui__Icon__LogOut from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__LogOut.bs.js";
import * as AppLoggedInUserWideMenuQuery_graphql from "../../../../relay-codegen/generated/AppLoggedInUserWideMenuQuery_graphql.bs.js";
import * as AppLoggedInUserWideMenu_query_graphql from "../../../../relay-codegen/generated/AppLoggedInUserWideMenu_query_graphql.bs.js";
import * as RescriptTamagui__Icon__ArrowLeftRight from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__ArrowLeftRight.bs.js";
import * as App__LoggedInUser__WideOrganizationList from "./App__LoggedInUser__WideOrganizationList.bs.js";
import * as App__LoggedInUser__WidePersonalOrganization from "./App__LoggedInUser__WidePersonalOrganization.bs.js";

var convertFragment = AppLoggedInUserWideMenu_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppLoggedInUserWideMenu_query_graphql.node, convertFragment, fRef);
}

function App__LoggedInUser__WideMenu$LogoutButton(props) {
  var onPress = props.onPress;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                    orientation: "horizontal",
                    style: {
                      alignItems: "center"
                    },
                    children: [
                      JsxRuntime.jsx(ReactNative.View, {
                            style: {
                              transform: [{
                                  rotateY: Style$ReactNative.deg(180)
                                }]
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                                      size: "ic_3",
                                      icon: RescriptTamagui__Icon__LogOut.make
                                    }))
                          }),
                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                            kind: "body3",
                            children: "Sign out"
                          })
                    ]
                  }),
              style: {
                alignSelf: "flex-start",
                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_3")),
                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_2")),
                paddingRight: Caml_option.some(Primitives__Styling.space("cs_2")),
                paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              onPress: (function (param) {
                  onPress();
                })
            });
}

function App__LoggedInUser__WideMenu$PersonalOrganization(props) {
  var match = use(props.queryRef);
  var loggedInUser = match.loggedInUser;
  if (loggedInUser !== undefined && loggedInUser.__typename === "LoggedInUser") {
    return JsxRuntime.jsx(App__LoggedInUser__WidePersonalOrganization.make, {
                loggedInUserRef: loggedInUser.fragmentRefs,
                onOrganizationPress: props.onOrganizationPress
              });
  } else {
    return JsxRuntime.jsx(App__Organization__WideItem.$$Error.make, {});
  }
}

function App__LoggedInUser__WideMenu$OrganizationListLoading(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                JsxRuntime.jsx(App__Organization__WideItem.Loading.make, {}),
                JsxRuntime.jsx(App__Organization__WideItem.Loading.make, {})
              ]
            });
}

function App__LoggedInUser__WideMenu$OrganizationListError(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                JsxRuntime.jsx(App__Organization__WideItem.$$Error.make, {}),
                JsxRuntime.jsx(App__Organization__WideItem.$$Error.make, {})
              ]
            });
}

function App__LoggedInUser__WideMenu$OrganizationList(props) {
  var match = use(props.queryRef);
  var loggedInUser = match.loggedInUser;
  if (loggedInUser !== undefined && loggedInUser.__typename === "LoggedInUser") {
    return JsxRuntime.jsx(App__LoggedInUser__WideOrganizationList.make, {
                loggedInUserRef: loggedInUser.fragmentRefs,
                onOrganizationPress: props.onOrganizationPress
              });
  } else {
    return JsxRuntime.jsx(App__LoggedInUser__WideMenu$OrganizationListError, {});
  }
}

function App__LoggedInUser__WideMenu$Base(props) {
  var onOrganizationPress = props.onOrganizationPress;
  var onLogoPress = props.onLogoPress;
  var queryRef = props.queryRef;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                flex: 1,
                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_3")),
                paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      style: {
                        paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3")),
                        paddingRight: Caml_option.some(Primitives__Styling.space("cs_3"))
                      },
                      children: [
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                              orientation: "horizontal",
                              style: {
                                alignItems: "center",
                                justifyContent: "space-between"
                              },
                              children: [
                                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                                      children: JsxRuntime.jsx(App__Logotype.make, {}),
                                      onPress: (function (param) {
                                          onLogoPress();
                                        })
                                    }),
                                ReactUtils__SimpleReact.renderIfSome(props.onSwitchIconPress, (function (onSwitchIconPress) {
                                        return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                                                    children: JsxRuntime.jsx(Primitives__Icon.make, {
                                                          size: "ic_3",
                                                          icon: RescriptTamagui__Icon__ArrowLeftRight.make
                                                        }),
                                                    onPress: (function (param) {
                                                        onSwitchIconPress();
                                                      })
                                                  });
                                      }))
                              ]
                            }),
                        JsxRuntime.jsx(App__ErrorBoundary.make, {
                              debugContext: "App__LoggedInUser__WideMenu PersonalOrganization",
                              children: JsxRuntime.jsx(Primitives__Suspense.make, {
                                    fallback: Caml_option.some(JsxRuntime.jsx(App__LoggedInUser__WidePersonalOrganization.Loading.make, {})),
                                    children: JsxRuntime.jsx(App__LoggedInUser__WideMenu$PersonalOrganization, {
                                          queryRef: queryRef,
                                          onOrganizationPress: onOrganizationPress
                                        })
                                  }),
                              FallbackComponent: (function (param) {
                                  return JsxRuntime.jsx(App__Organization__WideItem.$$Error.make, {});
                                })
                            }),
                        JsxRuntime.jsx(Primitives__Divider.make, {})
                      ]
                    }),
                JsxRuntime.jsx(Primitives__ScrollView.make, {
                      style: {
                        flex: 1
                      },
                      children: Caml_option.some(JsxRuntime.jsx(App__ErrorBoundary.make, {
                                debugContext: "App__LoggedInUser__WideMenu OrganizationList",
                                children: JsxRuntime.jsx(Primitives__Suspense.make, {
                                      fallback: Caml_option.some(JsxRuntime.jsx(App__LoggedInUser__WideMenu$OrganizationListLoading, {})),
                                      children: JsxRuntime.jsx(App__LoggedInUser__WideMenu$OrganizationList, {
                                            queryRef: queryRef,
                                            onOrganizationPress: onOrganizationPress
                                          })
                                    }),
                                FallbackComponent: (function (param) {
                                    return JsxRuntime.jsx(App__LoggedInUser__WideMenu$OrganizationListError, {});
                                  })
                              }))
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      style: {
                        paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3")),
                        paddingRight: Caml_option.some(Primitives__Styling.space("cs_3"))
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__Divider.make, {}),
                        JsxRuntime.jsx(App__LoggedInUser__WideMenu$LogoutButton, {
                              onPress: (function () {
                                  App__SignOut.signOut();
                                })
                            })
                      ]
                    })
              ]
            });
}

var Base = {
  make: App__LoggedInUser__WideMenu$Base
};

var convertVariables = AppLoggedInUserWideMenuQuery_graphql.Internal.convertVariables;

var convertResponse = AppLoggedInUserWideMenuQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, AppLoggedInUserWideMenuQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, AppLoggedInUserWideMenuQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(AppLoggedInUserWideMenuQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(AppLoggedInUserWideMenuQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(AppLoggedInUserWideMenuQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(AppLoggedInUserWideMenuQuery_graphql.node, convertVariables);

function App__LoggedInUser__WideMenu$Connected(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$1({
        unauthenticated: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(App__LoggedInUser__WideMenu$Base, {
              queryRef: match$1.fragmentRefs,
              onLogoPress: props.onLogoPress,
              onOrganizationPress: props.onOrganizationPress,
              onSwitchIconPress: props.onSwitchIconPress
            });
}

var Connected = {
  make: App__LoggedInUser__WideMenu$Connected
};

export {
  Base ,
  Connected ,
}
/* use Not a pure module */
