// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptTamagui__Icon__Eye from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Eye.bs.js";
import * as RescriptTamagui__Icon__EyeOff from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__EyeOff.bs.js";

function App__Auth__PasswordVisibilityToggle(props) {
  var onToggle = props.onToggle;
  return JsxRuntime.jsx(Primitives__Group.make, {
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                        size: props.size,
                        color: props.color,
                        icon: props.toggled ? RescriptTamagui__Icon__Eye.make : RescriptTamagui__Icon__EyeOff.make
                      })),
              onPress: (function () {
                  onToggle();
                })
            });
}

var make = App__Auth__PasswordVisibilityToggle;

export {
  make ,
}
/* Primitives__Icon Not a pure module */
