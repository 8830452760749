// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__Plus from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Plus.bs.js";
import * as App__Organization__CreateModal from "../../organization/createModal/App__Organization__CreateModal.bs.js";
import * as App__Organization__WideMenuItem from "../../organization/wideMenuItem/App__Organization__WideMenuItem.bs.js";
import * as App__LoggedInUser__SharedMenuFragment from "../sharedMenu/App__LoggedInUser__SharedMenuFragment.bs.js";

function App__LoggedInUser__WideOrganizationList$CreateOrganizationButton(props) {
  var onOrganizationCreated = props.onOrganizationCreated;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                      children: JsxRuntime.jsxs(Primitives__Group.make, {
                            gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                            orientation: "horizontal",
                            style: {
                              alignItems: "center",
                              padding: Caml_option.some(Primitives__Styling.space("cs_2"))
                            },
                            children: [
                              JsxRuntime.jsx(Primitives__Group.make, {
                                    style: {
                                      alignItems: "center",
                                      width: Caml_option.some(Primitives__Styling.dp(32))
                                    },
                                    children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                                              size: "ic_3",
                                              icon: RescriptTamagui__Icon__Plus.make
                                            }))
                                  }),
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    children: "Start new organization"
                                  })
                            ]
                          }),
                      onPress: (function (param) {
                          setShow(function (param) {
                                return true;
                              });
                        }),
                      hoverStyle: {
                        backgroundColor: Primitives__Styling.color("secondary_3")
                      }
                    }),
                JsxRuntime.jsx(App__Organization__CreateModal.make, {
                      show: match[0],
                      onClose: (function () {
                          setShow(function (param) {
                                return false;
                              });
                        }),
                      loggedInUserDataId: props.loggedInUserDataId,
                      onCreated: (function (organizationId) {
                          setShow(function (param) {
                                return false;
                              });
                          onOrganizationCreated(organizationId);
                        })
                    })
              ]
            });
}

function App__LoggedInUser__WideOrganizationList(props) {
  var onOrganizationPress = props.onOrganizationPress;
  var match = App__LoggedInUser__SharedMenuFragment.use(props.loggedInUserRef);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                match.organizations.edges.map(function (param) {
                      var match = param.node;
                      return JsxRuntime.jsx(App__Organization__WideMenuItem.make, {
                                  organizationRef: match.fragmentRefs,
                                  onOrganizationPress: onOrganizationPress
                                }, DecidrNodeId.toString(match.id));
                    }),
                JsxRuntime.jsx(App__LoggedInUser__WideOrganizationList$CreateOrganizationButton, {
                      loggedInUserDataId: match.__id,
                      onOrganizationCreated: onOrganizationPress
                    })
              ]
            });
}

var make = App__LoggedInUser__WideOrganizationList;

export {
  make ,
}
/* react Not a pure module */
