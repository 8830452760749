// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as AppLoggedInUserSharedMenuFragment_loggedInUser_graphql from "../../../../relay-codegen/generated/AppLoggedInUserSharedMenuFragment_loggedInUser_graphql.bs.js";

var convertFragment = AppLoggedInUserSharedMenuFragment_loggedInUser_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppLoggedInUserSharedMenuFragment_loggedInUser_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppLoggedInUserSharedMenuFragment_loggedInUser_graphql.node, convertFragment);
}

var getConnectionNodes = AppLoggedInUserSharedMenuFragment_loggedInUser_graphql.Utils.getConnectionNodes;

var Types;

var Operation;

export {
  getConnectionNodes ,
  Types ,
  Operation ,
  convertFragment ,
  use ,
  useOpt ,
}
/* RescriptRelay_Fragment Not a pure module */
