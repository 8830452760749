// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

var make = Tamagui.Separator;

export {
  make ,
}
/* make Not a pure module */
